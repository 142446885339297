import React, { useRef, useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import './PengantarUmum.css'

export default function PengantarUmum() {

    return (
        <>
        <div className="text-pengantar-container">
            
           <a className='text-pengantar-header-a'>
                Assalamu'alaikum Warahmatullahi Wabarakatuh
           </a>
           <a className='text-pengantar-a'>
                Dengan penuh rasa syukur dan harapan, kami mempersembahkan inisiatif desain masjid <i>(secara gratis)</i> sebagai ladang amal.
                Kami percaya bahwa masjid tidak hanya merupakan tempat ibadah, tetapi juga pusat kegiatan sosial, pendidikan, dan spiritual.
                Melalui desain ini, kami ingin menciptakan sebuah ruang yang tidak hanya memfasilitasi shalat,
                tetapi juga memperkuat ikatan komunitas dan memberdayakan generasi mendatang.
            </a>
    
           <a className='text-pengantar-a'>
           Melalui desain masjid <i>(secara gratis)</i>, kami berharap dapat membuka pintu untuk kesempatan amal yang luas.
           Dengan dukungan dan partisipasi Anda, masjid ini akan menjadi lebih dari sekadar tempat ibadah yang akan menjadi pusat berkumpul,
           belajar, dan berbagi. Mari bersama-sama menjadikannya sebagai ladang amal yang penuh berkah.
            </a>

        </div>
        {/** 
        <div className="text-pengantar-container">
            
           <a className='text-pengantar-align-left'>
                Visi
           </a>
           <a className='text-pengantar-a'>
           Visi kami adalah membangun sebuah masjid yang tidak hanya memenuhi kebutuhan ibadah tetapi juga menjadi pusat kegiatan komunitas yang berkelanjutan.
           Masjid ini dirancang dengan konsep yang mengutamakan kenyamanan, keberlanjutan, dan aksesibilitas, menjadikannya sebagai ladang amal yang bermanfaat
           bagi setiap individu yang berkunjung.
            </a>

            <a className='text-pengantar-align-left'>
            Komitmen
           </a>
           <a className='text-pengantar-a'>
           Sebagai bagian dari komitmen kami terhadap lingkungan, desain masjid ini mengintegrasikan teknologi ramah lingkungan dan material berkelanjutan.
           Kami percaya bahwa masjid ini akan menjadi contoh bagaimana arsitektur dapat berkontribusi pada pelestarian lingkungan sambil melayani kebutuhan
           spiritual dan sosial umat.
            </a>

            <a className='text-pengantar-align-left'>
            Keterlibatan Komunitas
           </a>
           <a className='text-pengantar-a'>
           Kami mengundang seluruh anggota komunitas untuk berpartisipasi dalam proses desain ini. Dengan melibatkan Anda,
           kami berharap masjid ini akan benar-benar mencerminkan kebutuhan dan harapan masyarakat.
           Setiap saran dan kontribusi Anda akan menjadi bagian penting dalam mewujudkan visi ini.
            </a>

            <a className='text-pengantar-align-left'>
            Dukungan
           </a>
           <a className='text-pengantar-a'>
           Melalui desain ini <i>(secara gratis)</i>, kami berharap dapat membuka pintu untuk kesempatan amal yang luas.
           Dengan dukungan dan partisipasi Anda, masjid ini akan menjadi lebih dari sekadar tempat ibadah yang akan menjadi pusat berkumpul,
           belajar, dan berbagi. Mari bersama-sama menjadikannya sebagai ladang amal yang penuh berkah.
            </a>

            <a className='text-pengantar-align-left'>
            Penutup
           </a>
           <a className='text-pengantar-a'>
           Kami memohon doa dan dukungan Anda agar inisiatif desain ini dapat terwujud dengan baik.
           Semoga masjid ini menjadi cahaya bagi umat dan ladang amal yang abadi.
           Terima kasih atas perhatian dan partisipasi Anda.
            </a>
        </div>
        */}

        </>
    )
}