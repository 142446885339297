import { useEffect, useRef } from 'react';
import './SlideDownNavbar.css'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import imgDarkLogo from "../../../assets/images/logo/logo_rekanata_200x37px-dark.png"
import imgWhiteLogo from "../../../assets/images/logo/logo_rekanata_200x37px-white.png"
import SideNav from '../sidenav/SideNav';

function SlidedownNavbar() {

    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("id-slide-down-navbar-section").style.top = "0";
            document.getElementById("id-slide-down-navbar-section").style.height = "45px";
        } else {
            document.getElementById("id-slide-down-navbar-section").style.top = "-100px";
            document.getElementById("id-slide-down-navbar-section").style.height = "45px";
        }
    }

    return (
        <div className="slide-down-navbar-section">

            <div className="slide-down-navbar-layer" id="id-slide-down-navbar-section">
                <div className='slide-down-navbar-container'>

                    <div className='layout-layer1-menu-side-nav' id='id-layout-layer1-menu-side-nav'>
                        <SideNav />
                    </div>

                    <div className='layout-layer1-menu-left-item'>
                        <img src={imgDarkLogo} className='m-slide-down-navbar-logo' />
                    </div>

                    <div className='layout-layer1-menu-center-item'>
                        <Link to="/" className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>Home</a></Link>
                        <div className='layout-layer1-dropdown-menu'>

                            <Link className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>Project</a></Link>

                            <div className="layout-layer1-dropdown-content">
                                <div className="layout-layer1-text-link">
                                    <Link to="/house-project" className="m-link-navbar-text-item"><a>Rumah</a></Link>
                                    <Link to="/interior-project" className="m-link-navbar-text-item"><a>Interior</a></Link>
                                    <Link to="/masterplan-project" className="m-link-navbar-text-item"><a>Masterplan</a></Link>
                                    <Link to="/commercial-project" className="m-link-navbar-text-item"><a>Komersial</a></Link>
                                    <Link to="/mosque-project" className="m-link-navbar-text-item"><a>Masjid</a></Link>
                                </div>
                            </div>
                        </div>

                        <Link to="/free-design" className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>Free Design</a></Link>
                        <Link to="/" className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>Blog</a></Link>
                        <Link to="/about-us" className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>About</a></Link>
                        <Link to="/service" className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>Service</a></Link>
                    </div>

                    <div className='layout-layer1-menu-right-item'>
                        <Link to="/contact-us" className="m-link-navbar-text-item"><a className='layout-layer1-text-menu'>Contact Us</a></Link>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default SlidedownNavbar