import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidAlWushulSlider from "./slider/MasjidAlWushulSlider";
import Footer from "../../../../../../Footer";

export default function MasjidAlWushul() {

  return (
    <div className="modal-img">
        <MasjidAlWushulSlider/>
        <Footer/>
    </div>
  );
}