import React from "react"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Footer from "../../pages/Footer";
import Home from "../../pages/Home";
import Blog from "./pages/Blog";
import ExampleBlog1 from "./pages/blog_content/ExampleBlog1";
import { Helmet } from "react-helmet";

function AppBlog() {
  return (
    <div className="app-blog">
      <Helmet>
        <title> Blog Rekanata </title>
        <meta name="description"
          content="Blog Kami Rekanata Kontraktor"/>
      </Helmet>
      <div><Blog /></div>
      <div><Footer /></div>
    </div>
  )
}

export default AppBlog;