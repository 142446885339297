import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_YUSNA_CEMPAKA_ADIPURA/00_masjid_al_yusna_cempaka_adipura_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_YUSNA_CEMPAKA_ADIPURA/01_masjid_al_yusna_cempaka_adipura.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_YUSNA_CEMPAKA_ADIPURA/02_masjid_al_yusna_cempaka_adipura.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_YUSNA_CEMPAKA_ADIPURA/03_masjid_al_yusna_cempaka_adipura.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_YUSNA_CEMPAKA_ADIPURA/04_masjid_al_yusna_cempaka_adipura.jpg";



let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Cempaka",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  }
];
