import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_1/00_masjid_cordova_cilodong_1_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_1/01_masjid_cordova_cilodong_1.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_1/masjid_cordova_cilodong_plan.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Cordova",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  }
];
