import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidRayaBumiOrangeSlider from "./slider/MasjidRayaBumiOrangeSlider";
import Footer from "../../../../../../Footer";

export default function MasjidRayaBumiOrange() {

  return (
    <div className="modal-img">
        <MasjidRayaBumiOrangeSlider/>
        <Footer/>
    </div>
  );
}