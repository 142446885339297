import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidCordova1Slider from "./slider/MasjidCordova1Slider";
import Footer from "../../../../../../Footer";

export default function MasjidCordova1() {

  return (
    <div className="modal-img">
        <MasjidCordova1Slider/>
        <Footer/>
    </div>
  );
}