import './OurServiceViewAll.css'
import DesignAndBuild from './page_section/DesignAndBuild'
import InteriorDesign from './page_section/InteriorDesign'
import Masjid from './page_section/Masjid'
import Masterplan from './page_section/Masterplan'
import Renovasi from './page_section/Renovasi'
import Footer from '../../../../Footer'
import Navbar2DarkBg from '../../../../../interface/navbar/Navbar2DarkBg'
import FloatingBackButton from '../../../../../interface/back_page_button/FloatingBackButton'
import { Helmet } from 'react-helmet'
import SmoothScrollToTop from '../../../../../interface/smooth_scroll_to_top/SmoothScrollToTop'

function OurServiceViewAll() {

     /** animation slide up */
     function reveal1() {
        var reveals = document.querySelectorAll(".reveal-card-1");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 100;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-1");
            } else {
                reveals[i].classList.remove("active-card-1");
            }
        }
    }

    window.addEventListener("scroll", reveal1);

    function reveal2() {
        var reveals = document.querySelectorAll(".reveal-card-2");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 200;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-2");
            } else {
                reveals[i].classList.remove("active-card-2");
            }
        }
    }

    window.addEventListener("scroll", reveal2);

    return (
        <div className='our-service-view-all-section fade' id='id-our-service-view-all-section'>

            <Helmet>
                <title>Layanan Kami Rekanata Konstruksi</title>
                <meta name="description" content="Layanan Kami Rekanata Konstruksi" />
                <link rel="canonical" href="https://rekanata.id/service"/>
            </Helmet>

            <div className='our-service-view-all-container'>
                <Navbar2DarkBg/>
                <div className='reveal-card-2'><DesignAndBuild /></div>
                <div className='reveal-card-1'><InteriorDesign /></div>
                <div className='reveal-card-1'><Masjid /></div>
                <div className='reveal-card-1'><Masterplan /></div>
                <div className='reveal-card-1'><Renovasi /></div>
            </div>

            <div>
                <FloatingBackButton/>
                <SmoothScrollToTop/>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    )
}

export default OurServiceViewAll