import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_UJUNG_GENTENG/00_masjid_ujung_genteng_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_UJUNG_GENTENG/01_masjid_ujung_genteng.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_UJUNG_GENTENG/02_masjid_ujung_genteng.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_UJUNG_GENTENG/03_masjid_ujung_genteng.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_UJUNG_GENTENG/04_masjid_ujung_genteng.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Ujung Genteng",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  }
];
