export default [
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/masjid/MASJID_DARUSSALAM/masjid-pnf-resolusi-1920x1080.jpg'),
        setTagInfo: "Masjid",
        setTitle: "Masjid Darussalam",
        setDescription: `Depok`,
        setNavigate: "null",
        setCompletedProject: "2021"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/masjid/MASJID_AL_FATH/masjid-al-fath-thumb.jpg'),
        setTagInfo: "Masjid",
        setTitle: "Masjid AL-FATH",
        setDescription: `Bandung`,
        setNavigate: "null",
        setCompletedProject: "2021"
    },
]