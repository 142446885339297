import './OurService.css'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import serviceLogoRumah from '../../../../assets/icon/home-page-25px.png';
import { Helmet } from 'react-helmet';

function OurService() {

    /** animation slide up 
    function reveal1() {
        var reveals = document.querySelectorAll(".reveal-card-1");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 100;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-1");
            } else {
                reveals[i].classList.remove("active-card-1");
            }
        }
    }

    window.addEventListener("scroll", reveal1);
    */
    
    return (
        <div className='our-service-section fade' id='id-our-service-section'>
            <div className='our-service-container'>
                
                <div className='our-service-intro'>

                </div>

                <div className='our-service-grid'>

                    <div className='our-service-row'>

                        <div className='our-service-row-item'>
                            {/*<img src={serviceLogoRumah} className='our-service-row-thumb-image' />*/}
                            <a className='our-service-row-text-header'>Desain and Build</a>
                            <a className='our-service-row-a'>
                                One stop solution untuk membangun rumah baru yang inovatif.
                                Dimulai dari Perancangan sampai dengan pembangunan kami kontrol agar sesuai dengan keinginan dan kebutuhan client.
                            </a>
                        </div>

                        <div>
                            <Link to="/service-detail:id/design-and-build" className="our-service-row-text-link">
                                <button className="our-service-row-button-action">Lihat detail mengenai proyek ini</button>
                            </Link>
                        </div>

                    </div>

                    <div className='our-service-row'>

                        <div className='our-service-row-item'>

                            <a className='our-service-row-text-header'>Renovasi</a>
                            <a className='our-service-row-a'>
                                Solusi untuk mendapatkan rumah yang lebih nyaman, lebih luas dan lebih sehat.
                                Kami analisa rumah lama sehingga dapatkan desain dan build yang inovatif, efektif dan efisien.
                            </a>
                        </div>

                        <div>
                            <Link to="/service-detail:id/renovasi" className="our-service-row-text-link">
                                <button className="our-service-row-button-action">Lihat detail mengenai proyek ini</button>
                            </Link>
                        </div>
                    </div>

                    <div className='our-service-row'>

                        <div className='our-service-row-item'>

                            <a className='our-service-row-text-header'>Interior Design</a>
                            <a className='our-service-row-a'>
                                Desain untuk mendapatkan interior ruangan yang nyaman dengan berfungsi secara optimal.
                                Furniture yang customize dan menselaraskan dengan furniture yang lama.
                            </a>
                        </div>

                        <div>
                            <Link to="/service-detail:id/interior-design" className="our-service-row-text-link">
                                <button className="our-service-row-button-action">Lihat detail mengenai proyek ini</button>
                            </Link>
                        </div>
                    </div>

                    <div className='our-service-row'>

                        <div className='our-service-row-item'>

                            <a className='our-service-row-text-header'>Masterplan</a>
                            <a className='our-service-row-a'>
                                Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga, outbond sampai dengan perencanaan kota.
                            </a>

                        </div>

                        <div>
                            <Link to="/service-detail:id/masterplan" className="our-service-row-text-link">
                                <button className="our-service-row-button-action">Lihat detail mengenai proyek ini</button>
                            </Link>
                        </div>
                    </div>

                    <div className='our-service-row'>

                        <div className='our-service-row-item'>

                            <a className='our-service-row-text-header'>Masjid</a>
                            <a className='our-service-row-a'>
                                Ladang Amal bagi arsitek dengan mendesain masjid dan mushola gratis dan produk akhirnya adalah mendapatkan denah, 3D eksterior dan RAB global
                            </a>
                        </div>

                        <div>
                            <Link to="/service-detail:id/masjid" className="our-service-row-text-link">
                                <button className="our-service-row-button-action">Lihat detail mengenai proyek ini</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='our-service-row-bottom-container'>
                    <Link to="/our-service-details:id/design-and-build" className="our-service-row-text-link">
                        <button className="our-service-row-button-action-view-all">Lihat semua detail mengenai proyek ini</button>
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default OurService