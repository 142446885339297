import './OurServiceDetailView.css'
import imageRenovasi from '../../../../../../assets/images/project/rumah/ANTO_HOUSE/rumah-anto-renovasi-thumb.jpg'

function Renovasi() {

     {/** animation slide up 
     function reveal1() {
        var reveals = document.querySelectorAll(".reveal-card-1");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 100;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-1");
            } else {
                reveals[i].classList.remove("active-card-1");
            }
        }
    }

    window.addEventListener("scroll", reveal1);

    function reveal2() {
        var reveals = document.querySelectorAll(".reveal-card-2");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 200;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-2");
            } else {
                reveals[i].classList.remove("active-card-2");
            }
        }
    }

    window.addEventListener("scroll", reveal2);
    */}

    return (
        <div className='our-service-view-detail-section' id='id-our-service-view-detail-section'>
            <div className='our-service-view-detail-container'>
                <div className='our-service-view-detail-container-grid'>

                    <div className='our-service-view-detail-left-row'>
                        <a className='our-service-view-detail-text-header'>
                            Renovasi
                        </a>
                        <a className='our-service-view-detail-text-item'>
                            One stop solution untuk membangun rumah  baru yang inovatif.
                            Dimulai dari Perancangan sampai dengan pembangunan kami kontrol agar sesuai dengan keinginan dan kebutuhan client.
                        </a>

                        <a className='our-service-view-detail-text-item'>
                        Transformasikan rumah Anda menjadi ruang yang lebih nyaman dan fungsional dengan jasa renovasi rumah kami yang profesional!.
                        Kami menawarkan layanan renovasi rumah lengkap, mulai dari perbaikan kecil hingga perombakan besar sesuai kebutuhan Anda.
                        Dapatkan desain yang segar dan modern untuk rumah Anda! Tim kami siap membantu mewujudkan renovasi impian Anda.
                        Dengan pengalaman bertahun-tahun, kami menjamin hasil renovasi yang berkualitas dan memuaskan.
                        Jangan biarkan rumah Anda terlihat usang! Hubungi kami untuk konsultasi gratis tentang proyek renovasi yang Anda inginkan.
                        Kami menyediakan solusi renovasi yang tepat dan efisien, menjadikan setiap sudut rumah Anda lebih menarik.
                        Kualitas dan kepuasan pelanggan adalah prioritas kami. Renovasi rumah Anda bersama kami dan rasakan perbedaannya!.
                        </a>

                    </div>

                    <div className='our-service-view-detail-right-row'>
                        <img src={imageRenovasi} className='our-service-view-detail-image' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Renovasi