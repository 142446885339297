import './OurServiceDetailView.css'
import imageInteriorDesign from '../../../../../../assets/images/project/rumah/FAWN_HOUSE/rumah-fawn-house-interior-thumb.jpg'

function InteriorDesign() {
    return (
        <div className='our-service-view-detail-section' id='id-our-service-view-detail-section'>
            <div className='our-service-view-detail-container'>
                <div className='our-service-view-detail-container-grid'>
                    <div className='our-service-view-detail-left-row'>
                        <a className='our-service-view-detail-text-header'>
                            Interior Design
                        </a>
                        <a className='our-service-view-detail-text-item'>
                            One stop solution untuk membangun rumah  baru yang inovatif.
                            Dimulai dari Perancangan sampai dengan pembangunan kami kontrol agar sesuai dengan keinginan dan kebutuhan client.
                        </a>

                        <a className='our-service-view-detail-text-item'>
                        Transformasikan ruang Anda menjadi tempat yang nyaman dan estetis dengan jasa desain interior kami yang profesional!.
                        Kami menyediakan solusi desain interior yang inovatif, menggabungkan fungsionalitas dan estetika untuk setiap ruangan.
                        Dari konsep hingga realisasi, tim ahli kami siap membantu mewujudkan desain interior impian Anda.
                        Menciptakan suasana yang sesuai dengan kepribadian Anda! Layanan desain interior kami menawarkan pendekatan yang personal dan detail.        
                        Dengan pengalaman bertahun-tahun, kami ahli dalam merancang ruang yang tidak hanya indah, tetapi juga fungsional.
                        Dapatkan konsultasi gratis untuk desain interior yang tepat! Kami siap mendengar kebutuhan dan preferensi Anda.                     
                        Mengoptimalkan setiap sudut ruang Anda dengan desain interior yang cerdas dan efisien. Hubungi kami untuk detail lebih lanjut!.
                        </a>
                    </div>

                    <div className='our-service-view-detail-right-row'>
                        <img src={imageInteriorDesign} className='our-service-view-detail-image' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InteriorDesign