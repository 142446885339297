import React from "react";
import './ListViewitemContainer.css'
import whatsappIcon from "../../assets/icon/menu-whatsapp-24px.png"
import photoProfil from "../../assets/icon/list-profil-logo.png"

/** funchtion date AM/PM */
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function ListViewitemContainer() {

    /** hide number phone */
    const myNumberPhone = () => {
        var x = document.getElementById("id-number-phone");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    let phonenumber = "+6282110002640";
    let tamplateTextMessage = "Saya mau konsultasi mengenai pembangunan rumah";

    /*
    const openWeb = () => {
        alert("test untuk click whatsapp");
        <a href={"https://api.whatsapp.com/send?phone="[phonenumber]}></a>
    };
    */

    /** For mobile WhatsApp */
    function openWhatsAppMobile() {
        window.open('whatsapp://send?text= https://www.youtube.com/watch?v=ohpCMpderow');  
    }  

    /** For web desktop WhatsApp */
    function openWhatsAppWebDesktop() {  
        /** https://api.whatsapp.com/send/?phone=%2B6282110002640&text=Saya+mau+konsultasi+mengenai+pembangunan+rumah&type=phone_number&app_absent=0 */
        /** window.open(`https://wa.me/message/VOK54KP6IFZME1?src=qr`); */
        window.open(`https://wa.me/${phonenumber}?text=${tamplateTextMessage}`);  
    }  

    return (
        <div className="chat-list-item">
            <div className="chat-list-item-container">
          
                <div className="list-chat-activity" onClick={openWhatsAppWebDesktop}>

                    <div className="online-indicator" />

                    <div className="profil-item">
                        <img src={photoProfil} className="icon" />
                    </div>
                    <div className="box-item">
                        <div className="header-title-number">
                            <p><b><a className="chat-list-title">REKANATA ~ </a></b></p>
                            <p><a className="chat-list-message" id="id-number-phone">{phonenumber}</a></p>
                        </div>
                        <p className="space" />
                        <p><a className="chat-list-message">Klik disini untuk memulai chat</a></p>
                        <p className="space" />
                        <p><a className="time-stamp">Terahir dilihat {formatAMPM(new Date)}</a></p>
                    </div>

                    <div className="icon-item">
                        <img src={whatsappIcon} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListViewitemContainer;