import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"
import './ErrorPage.css'

function ErrorPage() {

  function autoRefresh() {
    window.location = window.location.href;
  }
  setInterval('autoRefresh()', 1000);

  /** function refreshPage() {
    window.location.reload(true);
  }*/

  /** navigation from button click blog list item */
  const navigate = useNavigate();
  const navigateTo = (path_to) => {
      navigate(path_to);
      autoRefresh();
  };

  return (
    <div className="error-page-section">
      <div className="error-page-container">
        <div className="error-page-center-container">
          <h1 className="error-page-title">Halaman tidak ditemukan</h1>
          <a className="error-page-sub-title">Halaman yang diminta tidak ditemukan.</a>
          <a className="error-page-sub-title">Silahkan kembali ke Hompage.</a>
          <button className="error-page-button" onClick={() => navigateTo('/')}>Kembali ke Home Page</button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;