import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import './PortofolioItemComponent.css';
import portofolioImage from "../../../../assets/images/project/rumah/AWILIGAR_HOUSE/awiligar-house.jpg";

function Portofolio04() {

    const navigate = useNavigate();
    const navigateHome = () => {
        navigate('/detail-project/portofolio-awiligar-house');
    };

    return (
        <div className='portofolio-rumah-01' id='id-portofolio-01'>
            <div className="portofolio-rumah-01-container">
                <div className='portofolio-rumah-01-container-img'>
                    <img src={portofolioImage} alt="imgFoto" className="portofolio-rumah-01-image" />
                </div>

                <div className="portofolio-rumah-01-overlay" onClick={navigateHome}>
                   <h1>Awiligar House</h1>
                   <p>Bandung, Jawa Barat</p>
                </div>
            </div>
        </div>
    );
}

export default Portofolio04;