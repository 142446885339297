import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_BAITUL_MUSLIMIN/00_masjid_baitul_muslimin_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_BAITUL_MUSLIMIN/01_masjid_baitul_muslimin.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_BAITUL_MUSLIMIN/02_masjid_baitul_muslimin.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_BAITUL_MUSLIMIN/03_masjid_baitul_muslimin.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Baitul Muslimin",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  }
];
