import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"
import '../components/css/ProjectGallery.css'

import ImageData from "./MasjidImageData";
import MasjidFilter from './masjid_filter/MasjidFilter';

function Masjid() {

    return (
        <div className="gallery-house fade">

            <div className="gallery-house-hero-image">
                <div className="gallery-house-hero-text">
                    <h1 className='gallery-house-h1'>PROYEK MASJID</h1>
                </div>
            </div>

            {/** Include filter project gallery */}
            <MasjidFilter/>
            
            {/**
            <div className="row-house">

                <div className="column-house">
                    <div className="project-header" id="id-project-rumah">
                        <div className="project-container">
                            {ImageData.map((content) => (
                                <img src={content.DARUSSALAM_MOSQUE} alt="Thumb" className="image" />
                            ))}

                            <div className="project-overlay">
                                <div className='content-title'>
                                    <p>Darussalam Mosque</p>
                                </div>

                                <div className='content-year'>
                                    <p>2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column-house">
                    <div className="project-header" id="id-project-rumah">
                        <div className="project-container">
                            {ImageData.map((content) => (
                                <img src={content.AL_FATH_MOSQUE} alt="Thumb" className="image" />
                            ))}

                            <div className="project-overlay">
                                <div className='content-title'>
                                    <p>AL-FATH Mosque</p>
                                </div>

                                <div className='content-year'>
                                    <p>2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            */}

        </div>
    )
}

export default Masjid