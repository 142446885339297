import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidCordova2Slider from "./slider/MasjidCordova2Slider";
import Footer from "../../../../../../Footer";

export default function MasjidCordova2() {

  return (
    <div className="modal-img">
        <MasjidCordova2Slider/>
        <Footer/>
    </div>
  );
}