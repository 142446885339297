import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidMuhajirinSlider from "./slider/MasjidMuhajirinSlider";
import Footer from "../../../../../../Footer";

export default function MasjidMuhajirin() {

  return (
    <div className="modal-img">
        <MasjidMuhajirinSlider/>
        <Footer/>
    </div>
  );
}