import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/00_masjid_bumi_kiara_payung_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/01_masjid_bumi_kiara_payung.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/02_masjid_bumi_kiara_payung.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/03_masjid_bumi_kiara_payung.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/04_masjid_bumi_kiara_payung.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/05_masjid_bumi_kiara_payung.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/06_masjid_bumi_kiara_payung.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Baitul Muslimin",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  },
  {
    title: "",
    description: "",
    urls: image5,
  },
  {
    title: "",
    description: "",
    urls: image6,
  },
];
