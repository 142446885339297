import React, { useRef, useEffect, useState, lazy } from 'react'
import { useLocation } from 'react-router-dom';

/*
import Navbar2 from "../interface/navbar/Navbar2"
import ImageSlider from "../interface/carousel_image_slider/ImageSlider"
import Portofolio from "../pages/components/portofolio/Portofolio"
import FloatingWhatsApp from "../interface/floating_whatsapp/FloatingWhatsApp"
import Footer from "./Footer"
import OurService from './components/rekanata/pages/OurService'
*/

import { Helmet } from 'react-helmet'
//import public icon
import favicon from '../../favicon.ico';

/** perform loading lazy load */
const Navbar2 = lazy(() => import("../interface/navbar/Navbar2"));
const ImageSlider = lazy(() => import("../interface/carousel_image_slider/ImageSlider"));
const Portofolio = lazy(() => import("../pages/components/portofolio/Portofolio"));
const FloatingWhatsApp = lazy(() => import("../interface/floating_whatsapp/FloatingWhatsApp"));
const Footer = lazy(() => import("./Footer"));
const OurService = lazy(() => import("./components/rekanata/pages/OurService"));

function Home() {

  let titleApps = 'Rekanata Konstruksi - Jasa Kontraktor Bangunan Rumah, Kantor, Masjid, Ruko, Interior, Lanskap Amanah dan Profesional'

  /**
  function refreshPage() {
    window.location.reload(true);
  }
  */

  useEffect(() => {
    /* RUN ON LOAD HERE */
    
  }, [])

  return (
    <div className="home-container fade" id='id-home-container'>

        <Helmet>
          <title>{titleApps}</title>
          <meta name="description" content="Jasa Kontraktor Bangunan Rumah, Kantor, Masjid, Ruko, Interior, Lanskap Amanah dan Profesional"/>
          <meta name="keywords" content={
            `Rekanata Konstruksi,
            Kontraktor Profesional,
            Jasa Kontraktor Bangunan,
            Perusahaan Kontraktor,
            Perusahaan Kontraktor Bangunan,
            Perusahaan Jasa Kontraktor Rumah,
            Kontraktor Bangunan,
            Perusahaan Kontraktor,
            Jasa Kontraktor Terpercaya,
            Jasa Kontraktor Bandung,
            Jasa Kontraktor Jabodetabek,
            Jasa Kontraktor PIK2,
            Kontraktor Indonesia,
            Kontraktor PIK2,
            Kontraktor Pantai Indak Kapuk 2,
            Kontraktor Bogor,
            Kontraktor Bekasi,
            Kontraktor Jakarta,
            Kontraktor Depok,
            Kontraktor Jababeka,
            Kontraktor Jawa Barat,
            Kontraktor Jawa tengah,
            Kontraktor Jawa Timur,
            Kontraktor Bali,
            Kontraktor Banten,
            Kontraktor Terpercaya,
            Kontraktor Jabodetabek,
            Kontraktor Kantor,
            Kontraktor Masjid,
            Kontraktor Ruko,
            Kontraktor Interior dan Kontraktor Lanskap,
            Kontraktor Renovasi Rumah,
            Kontraktor Design And Build,
            Kontraktor Renovasi Rumah,
            Kontraktor Renovasi Kantor,
            Kontraktor Renovasi Bangunan,
            Kontraktor Renovasi Perkantoran,
            Kontraktor Renovasi Interior,
            Kontraktor Renovasi Lanskap,
            Kontraktor Renovasi Landscape,
            Kontraktor Komunikatif
            Planning & Design Landscape,`}/>
          
          {/** <meta name="author" content="Rekanata Konstruksi" /> */}
          {/**<link rel="icon" type="image/png" href={favicon} sizes="16x16" />*/}
          <link rel="icon" href={favicon}/>
          <link rel="canonical" href="https://rekanata.id/"/>

          <meta name="geo.region" content="ID" />
          <meta name="geo.placename" content="Bandung" />
          <meta name="geo.position" content="-6.948934;107.706861" />
          <meta name="ICBM" content="-6.948934, 107.706861" />
          
        </Helmet>

      <div id='id-home-container-navbar'>
        <Navbar2 />
      </div>
      <div>
        <ImageSlider />
      </div>
      <div>
        <Portofolio />
      </div>
      <div>
        <OurService />
      </div>
      <div>
        <FloatingWhatsApp />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Home;