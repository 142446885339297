import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_NURULISLAM_KARAWANG/00_masjid_nurulislam_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_NURULISLAM_KARAWANG/masjid_nurulislam_plan.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Nurulislam",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  }
];
