import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"
import image from "../../../assets/images/project/rumah/AWILIGAR_HOUSE/awiligar-house.jpg"
import './Blog.css'

import videoBackground from "../../../assets/raw/intro-hero-video.mp4"
import logoBlogNav from "../../../assets/images/logo/logo_rekanata_200x37px-dark.png"

import iconSocialBarInstagram from "../../../assets/icon/info-instagram-24px.png"
import iconSocialBarYoutube from "../../../assets/icon/info-youtube-logo-24px.png"
import iconRoundedLogo from "../../../assets/icon/logo_png.png"
import ExampleBlog1 from "./blog_content/ExampleBlog1"
import BlogFilter from "./blog_filter/BlogFilter"
import Navbar2 from "../../navbar/Navbar2"

function Blog() {

    // When the user scrolls down 20px from the top of the document, slide down the navbar
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("id-blog-nav").style.top = "0"
        } else {
            document.getElementById("id-blog-nav").style.top = "-100px"
        }
    }

    /* autoplay video */
    window.addEventListener('load', videoScroll);
    window.addEventListener('scroll', videoScroll);

    function videoScroll() {
        if (document.querySelectorAll('video[autoplay]').length > 0) {
            var windowHeight = window.innerHeight,
                videoEl = document.querySelectorAll('video[autoplay]');

            for (var i = 0; i < videoEl.length; i++) {

                var thisVideoEl = videoEl[i],
                    videoHeight = thisVideoEl.clientHeight,
                    videoClientRect = thisVideoEl.getBoundingClientRect().top;

                if (videoClientRect <= ((windowHeight) - (videoHeight * .5)) && videoClientRect >= (0 - (videoHeight * .5))) {
                    thisVideoEl.play();
                } else {
                    thisVideoEl.pause();
                }
            }
        }
    }

    /** animation slide up */
    function reveal() {
        var reveals = document.querySelectorAll(".blog-reveal-card");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 200;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("blog-active-card");
            } else {
                reveals[i].classList.remove("blog-active-card");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    /** navigation from button click blog list item */
    const navigate = useNavigate();
    const navigateTo = (path_to) => {
        navigate(path_to);
    };

    /** naviagte open url */
    const openUrl = (open_urls) => {
        /** https://www.youtube.com/channel/UCzOI07-Sd2PK7P9xW3-Dw5g/featured */
        /** https://www.instagram.com/rekanata.contractors/ */
        window.open(open_urls);
    };

    return (
        <div className="blog-section">

            <div className="blog-container-nav">
                <Navbar2/>
            </div>

            <div className="blog-container">

                <div className="blog-hero">
                    <div className='main-video-bg'>
                        <div className="video-bg-overlay">
                            
                        </div>

                        <video src={videoBackground} autoPlay loop muted className="video-bg-frame" />
                        
                        <div className="video-bg-content">
                            <div className="video-bg-intro-header">
                                <h1>Our Service</h1>
                            </div>

                            <div className="video-bg-horizontal-container p">
                                <div className="video-bg-horizontal-row">
                                    <p className="video-bg-big-title">Design and build</p>

                                    <p>
                                        One stop solution untuk membangun rumah  baru yang inovatif .
                                        Dimulai dari Perancangan sampai dengan pembangunan kami kontrol agar sesuai dengan keinginan  dan kebutuhan client.
                                    </p>
                                </div>

                                <div className="video-bg-horizontal-row">
                                    <p className="video-bg-big-title">Renovasi</p>
                                    <p>
                                        Solusi untuk mendapatkan  rumah yang lebih nyaman, lebih luas dan lebih sehat.
                                        Kami analisa rumah lama sehingga dapatkan desain dan build yang inovatif,  efektif dan efisien.
                                    </p>
                                </div>

                                <div className="video-bg-horizontal-row">
                                    <p className="video-bg-big-title">Interior Design</p>

                                    <p>
                                        Desain untuk mendapatkan interior ruangan yang nyaman dengan berfungsi secara optimal .
                                        Furniture yang customize dan menselaraskan dengan furniture yang lama.
                                    </p>
                                </div>

                                <div className="video-bg-horizontal-row">
                                    <p className="video-bg-big-title">Masterplan</p>

                                    <p>
                                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga, outbond sampai dengan perencanaan kota.
                                    </p>
                                </div>

                                <div className="video-bg-horizontal-row">
                                    <p className="video-bg-big-title">Masjid</p>

                                    <p>
                                        Ladang Amal bagi Arsitek dengan mendesain masjid dan mushola gratis dan produk akhirnya adalah mendapatkan  denah, 3D eksterior dan  RAB global.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/** kontent dibawah ini jika diperlukan bisa di untag, karena belum siap di input*/}

                {/**
                <div className="blog-section-hero-image">
                    <div className="blog-section-hero-text">
                        <a className="blog-section-hero-text-header">Company News</a>
                    </div>
                </div>
                */}

                {/** disable content on 150823*/}
                {/**<BlogFilter />*/}

            </div>

        </div>
    )
}

export default Blog