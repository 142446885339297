export default [
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/GW_HOUSE/grand-wisata.jpg'),
        setTagInfo: "Konstruksi",
        setTitle: "Rumah Minimalis",
        setDescription: "Rumah Mewah minimalis ini menawarkan lingkungan yang nyaman untuk tempat tinggal atau investasi untuk masa depan, Lokasinya strategis dekat dengan pintul tol akses transportasi, terminal, rumah sakit, hingga sarana pendidikan.",
        setNavigate: "/penggunaan-bahan-material"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/ANTO_HOUSE/rumah-anto-renovasi-thumb.jpg'),
        setTagInfo: "Konstruksi",
        setTitle: "Rumah Minimalis Skandinavian",
        setDescription: "Rumah Mewah minimalis ini menawarkan lingkungan yang nyaman untuk tempat tinggal atau investasi untuk masa depan, Lokasinya strategis dekat dengan pintul tol akses transportasi, terminal, rumah sakit, hingga sarana pendidikan.",
        setNavigate: "/penggunaan-bahan-material"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/GW_HOUSE/grand-wisata.jpg'),
        setTagInfo: "Konstruksi",
        setTitle: "Rumah Industrial 3423423",
        setDescription: "Rumah Mewah minimalis ini menawarkan lingkungan yang nyaman untuk tempat tinggal atau investasi untuk masa depan, Lokasinya strategis dekat dengan pintul tol akses transportasi, terminal, rumah sakit, hingga sarana pendidikan.",
        setNavigate: "/penggunaan-bahan-material"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/GW_HOUSE/grand-wisata.jpg'),
        setTagInfo: "Konstruksi",
        setTitle: "Rumah Industrial",
        setDescription: "Rumah Mewah minimalis ini menawarkan lingkungan yang nyaman untuk tempat tinggal atau investasi untuk masa depan, Lokasinya strategis dekat dengan pintul tol akses transportasi, terminal, rumah sakit, hingga sarana pendidikan.",
        setNavigate: "/penggunaan-bahan-material"
    },
]