import React from 'react';
import './Portofolio.css';

import Portofolio01 from './project_portofolio/Portofolio01';
import Portofolio02 from './project_portofolio/Portofolio02';
import Portofolio03 from './project_portofolio/Portofolio03';
import Portofolio04 from './project_portofolio/Portofolio04';
import Portofolio05 from './project_portofolio/Portofolio05';
import Portofolio06 from './project_portofolio/Portofolio06';
import Portofolio07 from './project_portofolio/Portofolio07';
import Portofolio08 from './project_portofolio/Portofolio08';
import Portofolio09 from './project_portofolio/Portofolio09';
import Portofolio10 from './project_portofolio/Portofolio10';

function Portofolio() {

    /** animation slide up */
    function reveal1() {
        var reveals = document.querySelectorAll(".reveal-card-1");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 100;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-1");
            } else {
                reveals[i].classList.remove("active-card-1");
            }
        }
    }

    window.addEventListener("scroll", reveal1);

    function reveal2() {
        var reveals = document.querySelectorAll(".reveal-card-2");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-2");
            } else {
                reveals[i].classList.remove("active-card-2");
            }
        }
    }

    window.addEventListener("scroll", reveal2);

    return (
        <div className='portofolio-section' id='portofolio'>
            <div className='portofolio-container'>

                <div className="portofolio-row">
                    <div className="portofolio-column">
                        <Portofolio01 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio02 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio03 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio04 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio05 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio06 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio07 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio08 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio09 />
                    </div>

                    <div className="portofolio-column">
                        <Portofolio10 />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portofolio;