import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_PARIGI/00_masjid_parigi_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_PARIGI/01_masjid_parigi.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Parigi",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  }
];
