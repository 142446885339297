import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidBaitulMusliminSlider from "./slider/MasjidBaitulMusliminSlider";
import Footer from "../../../../../../Footer";

export default function MasjidBaitulMuslimin() {

  return (
    <div className="modal-img">
        <MasjidBaitulMusliminSlider/>
        <Footer/>
    </div>
  );
}