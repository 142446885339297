import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar2 from "../interface/navbar/Navbar2"
import './FreeDesign.css'
import FloatingBackButton from "../interface/back_page_button/FloatingBackButton";
import { Helmet } from "react-helmet";
import MainActivityMasjid from "../pages/components/portofolio/design/MainActivityMasjid";
import iconScrollIndicator from "../../components/assets/icon/icon-mouse-white-91px.png"

function FreeDesign() {

  let titleApps = "Free Design Masjid - Layanan Desain Masjid Gratis";

  function composeEmail() {
    let EMAIL_TO = "kitastudionata@gmail.com";
    let EMAIL_SUBJECT = "Free Design Masjid";
    let EMAIL_BODY_MESSAGE = "";
    let EMAIL_BCC = "";

    let MAIL_TO_SUBJECT = ["mailto:" + { EMAIL_TO } + "?Subject=" + { EMAIL_SUBJECT }];
    // secondary way 
    let MAIL_TO = "https://mail.google.com/mail/?view=cm&fs=1&to=" + EMAIL_TO + "&su=" + EMAIL_SUBJECT + "&body=" + EMAIL_BODY_MESSAGE + "&bcc=" + EMAIL_BCC;
    window.open(`${MAIL_TO}`);
  }

  let phonenumber = "+6282110002640";
  let tamplateTextMessage = "Hallo saya mau konsultasi mengenai pembangunan rumah";

  /** For web desktop WhatsApp */
  function openWhatsAppWebDesktop(number) {
    /** window.open(`https://wa.me/message/VOK54KP6IFZME1?src=qr`); */
    window.open(`https://wa.me/${number}?text=${tamplateTextMessage}`);
  }

  {/* navigasi fokus halaman*/ }
  const navigateToScrollDown = (id) => {
    const releventDivS = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    releventDivS.scrollIntoView({
      behavior: "smooth"
    });
  }

  return (
    <div className="free-design-section fade" id="id-free-design-section">
      <Helmet>
        <title>{titleApps}</title>
        <meta name="description" content={`
            Free Design Masjid - Dengan penuh rasa syukur dan harapan, kami mempersembahkan inisiatif desain masjid ini (secara gratis) sebagai ladang amal.
            Kami percaya bahwa masjid tidak hanya merupakan tempat ibadah, tetapi juga pusat kegiatan sosial, pendidikan, dan spiritual. Melalui desain ini,
            kami ingin menciptakan sebuah ruang yang tidak hanya memfasilitasi shalat, tetapi juga memperkuat ikatan komunitas dan memberdayakan generasi mendatang.
            `} />

        <meta name="keywords" content={
          `Free Design Masjid,
             Free Desain Masjid,
             Buat Desain Masjid Secara Gratis,
             Konsultasi Desain Masjid,
             Desain Masjid Gratis,
             Design And Build,
             Layanan Desain masjid,
             Layanan Desain masjid Gratis,
             Desain Masjid Studio Nata,
             Desain Masjid Rekanata Konstruksi,
             Design Masjid,
             Desain Masjid,
             Design And Build Masjid,
             Renovasi Masjid
            `} />

        {/** <meta name="author" content="Rekanata Konstruksi" /> */}
        {/**<link rel="icon" type="image/png" href={favicon} sizes="16x16" />*/}
        <link rel="canonical" href="https://rekanata.id/free-design" />
      </Helmet>

      <Navbar2 />
      <div className="free-design-container">
        <div className="free-design-hero-text">
          <h1 className="free-design-hero-text-h1">Free design masjid</h1>
          <br />
          <p className="free-design-hero-text-p">Ingin mendapatkan Desain Masjid / Musola Gratis ?*</p>
          <p className="free-design-hero-text-p">*Produk Desain yang dikeluarkan : Denah, 3D eskterior dan  RAB Global</p>
          <br />
          <p className="free-design-hero-text-p">Cara nya :</p>
          <p className="free-design-hero-text-p">1. Siapkan Data masjid yang akan dibangun atau di  renovasi ?</p>
          <p className="free-design-hero-text-p"><li>Ukuran Tanah  yang diperuntukan masjid dan kelengkapannya.</li></p>
          <p className="free-design-hero-text-p"><li>Ukuran Masjid yang dihendaki (Berapa Lantai, dimana tempat wudlu, ingin seperti apa?).</li></p>
          <p className="free-design-hero-text-p"><li>Arah kiblat.</li></p>
          <p className="free-design-hero-text-p">2. Seluruh Data masjid tersebut di gambar/sketsa/curat coret,
            kirimkan ke WhatsApp <Link className="free-design-text-link" onClick={() => openWhatsAppWebDesktop(phonenumber)}> 0821-1000-2640 </Link> atau email ke <Link className='free-design-text-link' onClick={composeEmail}> rekanata.contractors@gmail.com </Link>
            dengan memperkenalkan diri dan menyebutkan lokasi masjid.
          </p>
          <p className="free-design-hero-text-p">3. Foto tanah atau bangunan eksisting bisa dikirimkan sebagai pelengkap.</p>
          <br />
          <button className="free-design-button-action" onClick={() => openWhatsAppWebDesktop(phonenumber)}>Konsultasikan dengan kami via WhatsApp <img src={require('../assets/icon/icon-whatsapp-48px.png')} className="fa-button-icon" /></button>
        </div>

        <div className="free-design-scroll-indicator-container">
          <a className="slide-container-free-design-text">Lihat portofolio desain masjid kami yang gratis dan temukan inspirasi untuk proyek Anda!</a>
          <img src={iconScrollIndicator} className="slide-container-free-design-img" onClick={() => navigateToScrollDown("id-activity-masjid")} /> {/** handle click event get ids */}
        </div>

      </div>

      <MainActivityMasjid />

      <div>
        <FloatingBackButton />
      </div>
    </div>
  )
}

export default FreeDesign