import React from 'react'
import Footer from '../Footer'
import "./ProjectMasterplan.css"
import Navbar2DarkBg from '../../interface/navbar/Navbar2DarkBg'
import TabsProjectGallery from './tabs/TabsProjectGallery'
import { Helmet } from 'react-helmet'

function ProjectMasterplan() {

  let TAB_MASTERPLAN = 3;

  return (
    <div className='app-project-masterplan'>
      <div className='project-masterplan-nav-header'>
        <Navbar2DarkBg />
      </div>

      <div className='project-masterplan-container'>
        <TabsProjectGallery page={TAB_MASTERPLAN}/>
      </div>

      <div className='project-masterplan-bottom-nav'>
        <Footer />
      </div>
    </div>
  )
}

export default ProjectMasterplan