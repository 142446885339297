import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidSmpMutiaraSlider from "./slider/MasjidSmpMutiaraSlider";
import Footer from "../../../../../../Footer";

export default function MasjidSmpMutiara() {

  return (
    <div className="modal-img">
        <MasjidSmpMutiaraSlider/>
        <Footer/>
    </div>
  );
}