import React from 'react'
import Footer from '../Footer'
import "./ProjectInterior.css"
import Navbar2DarkBg from '../../interface/navbar/Navbar2DarkBg'
import TabsProjectGallery from './tabs/TabsProjectGallery'

function ProjectInterior() {

  let TAB_INTERIOR = 2;

  return (
    <div className='app-project-interior'>
      <div className='project-interior-nav-header'>
        <Navbar2DarkBg/>
      </div>

      <div className='project-interior-container '>
        <TabsProjectGallery page={TAB_INTERIOR}/>
      </div>

      <div className='project-interior-bottom-nav'>
        <Footer />
      </div>
    </div>
  )
}

export default ProjectInterior