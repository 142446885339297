import './OurServiceDetailView.css'
import imageMasjid from '../../../../../../assets/images/project/masjid/MASJID_DARUSSALAM/masjid-pnf-resolusi-1920x1080.jpg'

function Masjid() {

    return (
        <div className='our-service-view-detail-section' id='id-our-service-view-detail-section'>

            <div className='our-service-view-detail-container'>
                <div className='our-service-view-detail-container-grid'>

                    <div className='our-service-view-detail-left-row'>
                        <a className='our-service-view-detail-text-header'>
                            FREE DESIGN MASJID
                        </a>
                        <a className='our-service-view-detail-text-item'>
                            Assalamu'alaikum Warahmatullahi Wabarakatuh
                        </a>
                        <a className='our-service-view-detail-text-item'>
                            Dengan penuh rasa syukur dan harapan, kami mempersembahkan inisiatif desain masjid <i>(secara gratis)</i> sebagai ladang amal.
                            Kami percaya bahwa masjid tidak hanya merupakan tempat ibadah, tetapi juga pusat kegiatan sosial, pendidikan, dan spiritual.
                            Melalui desain ini, kami ingin menciptakan sebuah ruang yang tidak hanya memfasilitasi shalat,
                            tetapi juga memperkuat ikatan komunitas dan memberdayakan generasi mendatang.
                        </a>

                        <a className='our-service-view-detail-text-item'>
                            Melalui desain masjid <i>(secara gratis)</i>, kami berharap dapat membuka pintu untuk kesempatan amal yang luas.
                            Dengan dukungan dan partisipasi Anda, masjid ini akan menjadi lebih dari sekadar tempat ibadah yang akan menjadi pusat berkumpul,
                            belajar, dan berbagi. Mari bersama-sama menjadikannya sebagai ladang amal yang penuh berkah.
                        </a>
                    </div>

                    <div className='our-service-view-detail-right-row'>
                        <img src={imageMasjid} className='our-service-view-detail-image' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Masjid