import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_SMP_MUTIARA/00_masjid_smp_mutiara_cover.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid SMP Mutiara",
    description: design_description,
    urls: image0,
  }
];
