import React from 'react'
import Footer from '../Footer'
import "./ProjectMasjid.css"
import Navbar2DarkBg from '../../interface/navbar/Navbar2DarkBg'
import TabsProjectGallery from './tabs/TabsProjectGallery'

function ProjectMasjid() {

  let TAB_MASJID = 5;
  
  return (
    <div className='app-project-masjid'>
      <div className='project-masjid-nav-header'>
        <Navbar2DarkBg/>
      </div>

      <div className='project-masjid-container'>
        <TabsProjectGallery page={TAB_MASJID}/>
      </div>

      <div className='project-masjid-bottom-nav'>
        <Footer />
      </div>
    </div>
  )
}

export default ProjectMasjid