import './Service.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import iconDesignAndBuild from "../assets/icon/icon-property2-64px.png";
import iconRenovation from "../assets/icon/icon-renovation-64px.png";
import iconInteriorDesign from "../assets/icon/icon-interior-64px.png";
import iconMasterplan from "../assets/icon/icon-architecture-64px.png";
import iconMasjid from "../assets/icon/icon-mosque-64.png";

import serviceBg from "../assets/images/project/rumah/AWILIGAR_HOUSE/awiligar-house.jpg";
import OurService from './components/rekanata/pages/OurService';
import Navbar2DarkBg from '../interface/navbar/Navbar2DarkBg';
import Footer from '../pages/Footer';
import { Helmet } from 'react-helmet';

function Service() {

    return (
        <div className='service-section' id='service'>
            <Helmet>
                <title>Layanan Kami Rekanata Konstruksi</title>
                <meta name="description" content="Layanan Kami Rekanata Konstruksi" />
                <link rel="canonical" href="https://rekanata.id/service"/>
            </Helmet>

            <div className='service-main-container'>
                <div>
                    <Navbar2DarkBg />
                </div>

                <div>
                    <OurService />
                </div>

                <div>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default Service