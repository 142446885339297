import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidNurulislamSlider from "./slider/MasjidNurulislamSlider";
import Footer from "../../../../../../Footer";

export default function MasjidNurulislam() {

  return (
    <div className="modal-img">
        <MasjidNurulislamSlider/>
        <Footer/>
    </div>
  );
}