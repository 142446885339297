import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_SUKAMANAH/00_masjid_sukamanah_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_SUKAMANAH/01_masjid_sukamanah.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_SUKAMANAH/02_masjid_sukamanah.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_SUKAMANAH/03_masjid_sukamanah.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Sukamanah",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  }
];
