import React, { useRef, useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

import './MainActivityMasjid.css'
import DesignMasjidFilter from '../design/design_masjid/DesignMasjidFilter';
import Footer from '../../../Footer';
import PengantarUmum from './PengantarUmum';
import SmoothScrollToTop from '../../../../interface/smooth_scroll_to_top/SmoothScrollToTop';

function MainActivityMasjid() {

    /** navigation to from div clicked */
    const navigate = useNavigate();
    /** onClick={() => { navigate('/detail-project/fawn-house') }} */
    

    return (
        <>
        <div className="gallery-design fade" id='id-activity-masjid'>

            <div className="gallery-design-hero-image">
                <div className="gallery-design-hero-text">
                    <h1 className='gallery-design-h1'>FREE DESIGN MASJID</h1>
                </div>
            </div>

            <PengantarUmum/>

            {/** Include filter project gallery design */}
            <DesignMasjidFilter/>

        </div>

        <Footer/>
        <SmoothScrollToTop/>
        
        </>
    )
}

export default MainActivityMasjid