let proposal = "Proposal Desain Masjid";

export default [
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_PADANG/00_masjid_padang_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Padang",
        setDescription: `Padang, Sumatra Barat`,
        setNavigate: "/design-masjid-padang",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_TUMBUH/00_masjid_tumbuh_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Tumbuh",
        setDescription: ``,
        setNavigate: "/design-masjid-tumbuh",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/00_masjid_al_azhar_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Al-Azhar",
        setDescription: ``,
        setNavigate: "/design-masjid-al-azhar",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_BUMI_KIARA_PAYUNG/00_masjid_bumi_kiara_payung_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Bumi Kiarapayung",
        setDescription: `Kiarapayung, Kab.Bandung, Jawa Barat`,
        setNavigate: "/design-masjid-bumi-kiara-payung",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_BAITUL_MUSLIMIN/00_masjid_baitul_muslimin_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Baitul Muslimin",
        setDescription: ``,
        setNavigate: "/design-masjid-baitul-muslimin",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_1/00_masjid_cordova_cilodong_1_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Cordova",
        setDescription: `Cilodong, Depok, Jawa Barat`,
        setNavigate: "/design-masjid-cordova1",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_2/00_masjid_cordova_cilodong_2_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Cordova",
        setDescription: `Cilodong, Depok, Jawa Barat`,
        setNavigate: "/design-masjid-cordova2",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_KALANG/00_masjid_kalang_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Kalang",
        setDescription: `Kalangsari, Jawa Barat`,
        setNavigate: "/design-masjid-kalang1",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_KALANGSARI/00_masjid_kalangsari_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Kalangsari",
        setDescription: `Kalangsari, Jawa Barat`,
        setNavigate: "/design-masjid-kalang2",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_MIFTAHURRAHMAN_BOGOR/00_masjid_miftahurrahmah_bogor_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Miftahurrahmah",
        setDescription: `Bogor, Jawa Barat`,
        setNavigate: "/design-masjid-miftahurrahman",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/00_masjid_muhajirin_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Muhajirin",
        setDescription: `Bandung, Jawa Barat`,
        setNavigate: "/design-masjid-muhajirin",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/00_masjid_pondok_pinang_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Pondok Pinang",
        setDescription: `Pondok Pinang, Jakarta Selatan`,
        setNavigate: "/design-masjid-pondok-pinang",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_RAYA_BUMI_ORANGE/00_masjid_raya_bumi_orange_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Raya Bumi Orange",
        setDescription: `Bandung, Jawa Barat`,
        setNavigate: "/design-masjid-raya-bumi-orange",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_ACEH/00_masjid_aceh_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Aceh",
        setDescription: ``,
        setNavigate: "/design-masjid-aceh",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_AL_YUSNA_CEMPAKA_ADIPURA/00_masjid_al_yusna_cempaka_adipura_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Al-Yusna Cempaka Adipura",
        setDescription: `Bandung, Jawa Barat`,
        setNavigate: "/design-masjid-cempaka",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/00_masjid_al_wushul_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Al-Wushul",
        setDescription: `Kab.Sumedang, Jawa Barat`,
        setNavigate: "/design-al-wushul",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_DARUL_MUTTAQIN_BOJONEGORO/00_masjid_darul_muttaqin_bojonegoro_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Darul Muttaqin",
        setDescription: ``,
        setNavigate: "/design-masjid-darul-muttaqin",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_ASY_SYIFA/00_masjid_asy_syifa_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Asy-Syifa",
        setDescription: ``,
        setNavigate: "/design-masjid-asy-syifa",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_KAF_PWK/00_masjid_kaf_pwk_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid KAF PWK",
        setDescription: `Purwakarta, Jawa Barat`,
        setNavigate: "/design-masjid-kaf-pwk",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_KERTAYASA/00_masjid_kertayasa_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Kertayasa",
        setDescription: `Kertayasa, Cijulang, Jawa Barat`,
        setNavigate: "/design-masjid-kertayasa",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_LELA/00_masjid_lela_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Lela",
        setDescription: ``,
        setNavigate: "/design-masjid-lela",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_NURULISLAM_KARAWANG/00_masjid_nurulislam_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Nurul Islam",
        setDescription: ``,
        setNavigate: "/design-masjid-nurulislam",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_PACET/00_masjid_pacet_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Pacet",
        setDescription: `Pacet, Kab.Bandung, Jawa Barat`,
        setNavigate: "/design-masjid-pacet",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_PARIGI/00_masjid_parigi_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Parigi",
        setDescription: `Parigi, Sulawesi Tengah`,
        setNavigate: "/design-masjid-parigi",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_SMP_MUTIARA/00_masjid_smp_mutiara_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid SMP Mutiara",
        setDescription: `Bandung, Jawa Barat`,
        setNavigate: "/design-masjid-smp-mutiara",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_SRAGEN/00_masjid_sragen_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Sragen",
        setDescription: `Sragen, Jawa Tengah`,
        setNavigate: "/design-masjid-sragen",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_SUKAMANAH/00_masjid_sukamanah_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Sukamanah",
        setDescription: `Garut, Jawa Barat`,
        setNavigate: "/design-masjid-sukamanah",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../../assets/images/design_masjid/MASJID_UJUNG_GENTENG/00_masjid_ujung_genteng_cover.jpg'),
        setTagInfo: proposal,
        setTitle: "Masjid Al-Anshor",
        setDescription: `Sukabumi, Jawa Barat`,
        setNavigate: "/design-masjid-ujung-genteng",
        setCompletedProject: ""
    },
]