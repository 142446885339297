export const GalleryData = [
    {
        id:1,
        tile:"Bekasi",
        image: require('../../assets/images/project/rumah/GW_HOUSE/grand-wisata.jpg')
    },
    {
        id:2,
        tile:"Bogor",
        image: require('../../assets/images/project/rumah/CIFOR_HOUSE/00_cover.jpg')
    },
    {
        id:3,
        tile:"Depok",
        image: require('../../assets/images/project/rumah/FLK_HOUSE/0_thumb_flk_house.jpg')
    },
    {
        id:4,
        tile:"Bandung",
        image: require('../../assets/images/project/rumah/AWILIGAR_HOUSE/awiligar-house.jpg')
    },
    {
        id:5,
        tile:"Tasik",
        image: require('../../assets/images/project/rumah/OD_HOUSE/rumah-od-house.jpg')
    },
    {
        id:5,
        tile:"Jakarta",
        image: require('../../assets/images/project/rumah/NUR_HOUSE/1_nur_house.jpg')
    },
]