import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/00_masjid_tumbuh_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/01_masjid_tumbuh.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/02_masjid_tumbuh.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/03_masjid_tumbuh.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/04_masjid_tumbuh.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/05_masjid_tumbuh.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/06_masjid_tumbuh.jpg";
import image7 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/07_masjid_tumbuh.jpg";
import image8 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/08_masjid_tumbuh.jpg";
import image9 from "../../../../../../../../assets/images/design_masjid/MASJID_TUMBUH/09_masjid_tumbuh.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Tumbuh",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  },
  {
    title: "",
    description: design_description,
    urls: image5,
  },
  {
    title: "",
    description: design_description,
    urls: image6,
  },
  {
    title: "",
    description: design_description,
    urls: image7,
  },
  {
    title: "",
    description: design_description,
    urls: image8,
  },
  {
    title: "",
    description: design_description,
    urls: image9,
  },
];
