import { useState } from "react";
import Masterplan from "../../project_gallery/masterplan/Masterplan";
import Interior from "../interior/Interior";
import Masjid from "../masjid/Masjid";
import Rumah from "../rumah/Rumah";
import Komersial from "../komersial/Komersial";
import "../components/css/Tabs.css";
import { Helmet } from "react-helmet";
import SmoothScrollToTop from "../../../interface/smooth_scroll_to_top/SmoothScrollToTop";
import FloatingBackButton from "../../../interface/back_page_button/FloatingBackButton";

function TabsProjectGallery({ page }) {
  const [toggleState, setToggleState] = useState(page);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const titleName = [
    {
      page1: "Rumah",
      page2: "Interior",
      page3: "Masterplan",
      page4: "Komersial",
      page5: "Masjid",
    }
  ]

  let titleApps = "Galeri Proyek Rekanata Konstruksi";

  return (
    <div className="tabs-container">
      <Helmet>
          <title>{titleApps}</title>
          <meta name="description" content="Galeri Proyek Rekanata Konstruksi"/>
          <meta name="keywords" content={
            `Rekanata Konstruksi,
            Kontraktor Profesional,
            Kontraktor Bangunan,
            Kontraktor Rumah Minimalis,
            Kontraktor Rumah Klasik,
            Kontraktor Rumah Jepang,
            Kontraktor Rumah Japandi,
            Kontraktor Rumah Skandinavian,
            Kontraktor Rumah Kontemporer,
            Kontraktor Rumah Korea,
            Kontraktor Rumah Industrial,
            Kontraktor Rumah Mediterania,
            Kontraktor Rumah Rustik,
            Kontraktor Rumah Tropis,
            Kontraktor Rumah Attic,
            Kontraktor Rumah Modern,
            Kontraktor Interior Minimalis,
            Kontraktor Interior Klasik,
            Kontraktor Interior Jepang,
            Kontraktor Interior Japandi,
            Kontraktor Interior Skandinavian,
            Kontraktor Interior Kontemporer,
            Kontraktor Interior Korea,
            Kontraktor Interior Industrial,
            Kontraktor Interior Mediterania,
            Kontraktor Interior Rustik,
            Kontraktor Interior Tropis,
            Kontraktor Interior Attic,
            Kontraktor Interior Modern
            `}/>
          <link rel="canonical" href="https://rekanata.id/house-project"/>
        </Helmet>

      {titleName.map((title) => (
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>
            {title.page1}
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
            {title.page2}
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)}>
            {title.page3}
          </button>
          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(4)}>
            {title.page4}
          </button>
          <button
            className={toggleState === 5 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(5)}>
            {title.page5}
          </button>
        </div>
      ))}

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}>
          <Rumah />
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}>
          <Interior />
        </div>

        <div
          className={toggleState === 3 ? "content  active-content" : "content"}>
          <Masterplan />
        </div>

        <div
          className={toggleState === 4 ? "content  active-content" : "content"}>
          <Komersial />
        </div>

        <div
          className={toggleState === 5 ? "content  active-content" : "content"}>
          <Masjid />
        </div>
      </div>
      
      <SmoothScrollToTop/>
      <FloatingBackButton/>
    </div>
  );
}

export default TabsProjectGallery;