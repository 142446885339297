import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidDarulMuttaqinSlider from "./slider/MasjidDarulMuttaqinSlider";
import Footer from "../../../../../../Footer";

export default function MasjidDarulMuttaqin() {

  return (
    <div className="modal-img">
        <MasjidDarulMuttaqinSlider/>
        <Footer/>
    </div>
  );
}