import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/00_masjid_al_azhar_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/01_masjid_al_azhar.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/02_masjid_al_azhar.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/03_masjid_al_azhar.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/04_masjid_al_azhar.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/05_masjid_al_azhar.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/06_masjid_al_azhar.jpg";
import image7 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/07_masjid_al_azhar.jpg";
import image8 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/08_masjid_al_azhar.jpg";
import image9 from "../../../../../../../../assets/images/design_masjid/MASJID_AL_AZHAR/09_masjid_al_azhar.jpg";



let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Al-Azhar",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  },
  {
    title: "",
    description: "",
    urls: image5,
  },
  {
    title: "",
    description: "",
    urls: image6,
  },
  {
    title: "",
    description: "",
    urls: image7,
  },
  {
    title: "",
    description: "",
    urls: image8,
  },
  {
    title: "",
    description: "",
    urls: image9,
  }
];
