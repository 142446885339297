import './OurServiceDetailView.css'
import imageDesignAndBuild from '../../../../../../assets/images/project/rumah/HNS_HOUSE/rumah-hans-design-and-build-thumb.jpg'

function DesignAndBuild() {
    return (
        <div className='our-service-view-detail-section' id='id-our-service-view-detail-section'>
            <div className='our-service-view-detail-container'>
                <div className='our-service-view-detail-container-grid'>
                    <div className='our-service-view-detail-left-row'>
                        <a className='our-service-view-detail-text-header'>
                            Design And Build
                        </a>
                        <a className='our-service-view-detail-text-item'>
                            One stop solution untuk membangun rumah  baru yang inovatif.
                            Dimulai dari Perancangan sampai dengan pembangunan kami kontrol agar sesuai dengan keinginan dan kebutuhan client.
                        </a>
                        <a className='our-service-view-detail-text-item'>
                        Kami menyediakan jasa desain dan build bangunan yang komprehensif, mulai dari perencanaan hingga penyelesaian proyek.
                        Wujudkan proyek impian Anda dengan tim ahli kami yang siap memberikan solusi desain dan konstruksi berkualitas.
                        Dengan pendekatan yang terintegrasi, kami memastikan bahwa setiap detail desain dan pembangunan sesuai dengan kebutuhan Anda.
                        Dari rumah tinggal hingga gedung komersial, kami memiliki pengalaman dan keahlian untuk menangani berbagai proyek dengan efisien.
                        Kami mengutamakan kualitas dan kepuasan pelanggan, memastikan hasil akhir yang tidak hanya menarik, tetapi juga fungsional.
                        Konsultasikan proyek Anda dengan kami! Tim desain dan build kami siap membantu mewujudkan visi Anda.
                        Percayakan kebutuhan desain dan pembangunan Anda kepada kami, dan nikmati proses yang mudah dan transparan.
                        </a>
                    </div>

                    <div className='our-service-view-detail-right-row'>
                        <img src={imageDesignAndBuild} className='our-service-view-detail-image' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DesignAndBuild