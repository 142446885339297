import React, { useEffect, useState } from "react";
import DetailPortofolioArrows from "../../../../../detail_portofolio/components/DetailPortofolioArrows";
import DetailPortofolioSliderContent from "../../../../../detail_portofolio/components/DetailPortofolioSliderContent";
import DetailPortofolioDots from "../../../../../detail_portofolio/components/DetailPortofolioDots";
import sliderImage from "./MasjidSragenData";

import "../../../../../detail_portofolio/css/DetailPortofolioImageSlider.css";

let TIMER = 50000;
const len = sliderImage.length - 1;

export default function MasjidSragenSlider(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, TIMER);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="portofolio-slider-container">
      <DetailPortofolioSliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
      <DetailPortofolioArrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }/>
        
      <DetailPortofolioDots
        activeIndex={activeIndex}
        sliderImage={sliderImage}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}