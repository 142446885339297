/*
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function DefaultScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });
  }, [pathname]);

  return null;
}
*/


import { useEffect } from "react";
import { useLocation } from "react-router-dom";
  
export default function DefaultScrollToTop() {
  
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    onTop()
  }, [routePath]);
  
  return null;
}


/** update on 22 nov */
/*
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function DefaultScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)

}, [pathname]);

  return null;
}
*/