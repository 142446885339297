import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_KAF_PWK/00_masjid_kaf_pwk_cover.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid KAF PWK",
    description: design_description,
    urls: image0,
  }
];
