export default [
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/killerimage/01.jpg'),
        setTagInfo: "Masterplan",
        setTitle: "Komersial",
        setDescription: ``,
        setNavigate: "null",
        setCompletedProject: ""
    },
]