import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_ACEH/00_masjid_aceh_cover.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_ACEH/01_masjid_aceh_denah.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Aceh",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  }
];
