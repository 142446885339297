import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"
import React, { useRef, useState, useEffect } from 'react'
import './DesignMasjidFilter.css'
import data from '../design_masjid/DesignMasjidFilterData'

export default function DesignMasjidFilter() {
    const ref = useRef(null);

    //const listCategoryItem = ['konstruksi', 'renovasi'];
    //const [filter, setFilter] = useState('');

    const [stringValue, setTextValue] = useState('')
    const [stringFilter, setUpdated] = useState(stringValue)
    const [stringButtonValue, setClearResultFilter] = useState(stringFilter)

    const [stringNotFound, setSearchNotFound] = useState(stringValue)

    const handleChange = (event) => {
        setTextValue(event.target.value)
        setClearResultFilter(event.target.value)
    };

    const handleClick = () => {
        // message" stores input field value
        setUpdated(stringValue)
        setTextValue('')
        /** set focused input 
        ref.current.focus();
        */
    };

    const requestDisableInputFilter = () => {
        this.setState({ disabled: !this.state.disabled })
    }

    const clearResultFilter = () => {
        setTextValue('')
        setUpdated('')
        setClearResultFilter('')
    }

    const onSearchNotFound = (not_found) => {
        setSearchNotFound(not_found)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick()
        }
    }

    /** navigation from button click blog list item */
    const navigate = useNavigate();
    const navigateTo = (path_to) => {
        navigate(path_to);
    };

    useEffect(() => {
        
    });

    return (
        <div className="m-design-filter-section">
            <div className="m-design-filter-container">

                <div className="m-design-filter-text-input-container">

                    <div className="m-design-filter-text-input-top-container">
                        <div className="m-design-filter-text-input-row">
                            <input
                                className="m-design-filter-input"
                                ref={ref}
                                id="filter"
                                name="filter"
                                type="text"
                                autocomplete="off"
                                placeholder="Cari dan temukan di ini - Kata kunci"
                                value={stringValue}
                                onChange={handleChange}
                                onClick={requestDisableInputFilter}
                                onKeyDown={handleKeyDown} />

                            <button className="m-design-filter-button" onClick={handleClick}><img src={require('../../../../../assets/icon/icon-search-24px.png')} className="m-design-filter-buttonfa-button-icon" />Cari</button>
                        </div>

                        <div className="m-design-filter-text-input-row">

                            <div className="m-design-filter-clear-button-container">

                                <button className="m-design-filter-button-clear-filter" onClick={clearResultFilter}><img src={require('../../../../../assets/icon/icon-delete-trash-24px.png')} className="m-design-filter-buttonfa-button-icon" />Hapus pencarian</button>

                                <input
                                    className="m-design-filter-input-result"
                                    ref={ref}
                                    id="filter"
                                    name="filter"
                                    type="text"
                                    autocomplete="off"
                                    placeholder=""
                                    value={stringFilter}
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="m-design-filter-grid" id="">
                        {
                            data
                                .filter((val) => {
                                    if (stringFilter == "") {
                                        return val;
                                    } else if (
                                        val.setTagInfo.toLowerCase().includes(stringFilter.toLowerCase()) ||
                                        val.setTitle.toLowerCase().includes(stringFilter.toLowerCase()) ||
                                        val.setDescription.toLocaleLowerCase().includes(stringFilter.toLowerCase()) ||
                                        val.setCompletedProject.toLocaleLowerCase().includes(stringFilter.toLowerCase())) {
                                        return val;
                                    }
                                })

                                .map((val) => {
                                    return (
                                        <div className="m-design-filter-row ">
                                            <div className="m-design-filter-card" key={val.id}>
                                                <div className="m-design-filter-card-top-container">
                                                    <img className="m-design-filter-row-image" src={val.setImage} alt="proyek rumah | desain rumah | portofolio proyek rumah | portofolio" />
                                                </div>

                                                <div className="m-design-filter-card-bottom-container">
                                                    <a className="m-design-filter-tag-text">{val.setTagInfo}</a>
                                                    <a className="m-design-filter-title-text"><b>{val.setTitle}</b></a>
                                                    <a className="m-design-filter-description-text" alt={val.setDescription}>{val.setDescription}</a>
                                                    <button className="m-design-filter-button-read-more" onClick={()=> navigateTo(val.setNavigate)}>Lebih Banyak Tampilan</button>
                                                    
                                                    {/**<button className="m-filter-button-read-more" onClick={() => navigateTo(val.setNavigate)}>{val.setCompletedProject}</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}