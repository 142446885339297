import React, { useEffect } from "react";
import Footer from "../../pages/Footer";
import FloatingBackButton from "../../interface/back_page_button/FloatingBackButton"
import './MapsLocation.css';

function MapsLocation() {
    useEffect(()=>{
        const ifameData = document.getElementById("iframeId")
        const lat = -6.948928;
        const lon = 107.706778;
      
        ifameData.src=`https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
    })

    return(
        <div className="maps-container">
            <iframe id="iframeId" className="maps"></iframe>

            <div>
                <FloatingBackButton/>
            </div>

            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default MapsLocation;