import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidTumbuhSlider from "./slider/MasjidTumbuhSlider";
import Footer from "../../../../../../Footer";

export default function MasjidTumbuh() {

  return (
    <div className="modal-img">
        <MasjidTumbuhSlider/>
        <Footer/>
    </div>
  );
}