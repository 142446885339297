import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidSragenSlider from "./slider/MasjidSragenSlider";
import Footer from "../../../../../../Footer";

export default function MasjidSragen() {

  return (
    <div className="modal-img">
        <MasjidSragenSlider/>
        <Footer/>
    </div>
  );
}