import React, { useEffect, useState } from "react";
import DetailPortofolioArrows from "../components/DetailPortofolioArrows";
import DetailPortofolioSliderContent from "../components/DetailPortofolioSliderContent";
import DetailPortofolioDots from "../components/DetailPortofolioDots";
import sliderImage from "./DetailPortofolioImageData";

import "../css/DetailPortofolioImageSlider.css";

let TIMER = 50000;
const len = sliderImage.length - 1;

function DetailPortofolioImageSlider(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, TIMER);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="portofolio-slider-container">
      <DetailPortofolioSliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
      <DetailPortofolioArrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }/>
        
      <DetailPortofolioDots
        activeIndex={activeIndex}
        sliderImage={sliderImage}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default DetailPortofolioImageSlider;
