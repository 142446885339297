import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidCempakaSlider from "./slider/MasjidCempakaSlider";
import Footer from "../../../../../../Footer";

export default function MasjidCempaka() {

  return (
    <div className="modal-img">
        <MasjidCempakaSlider/>
        <Footer/>
    </div>
  );
}