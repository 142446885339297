import React, { useState, useEffect, useRef } from 'react';
import '../css/DetailPortofolio.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CiforImageSlider from './CiforImageSlider';
import Footer from '../../../../Footer';
import FloatingBackToHome from '../../../../../interface/back_to_home/FloatingBackToHome';

function AppPortofolioCiforHouse() {

  /** EDIT: React v16.8+ (bisa dipakai untuk daefault to top page)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  */
  
  useEffect(() => ref.current.scrollTo(0, 0));
  const ref = useRef();

  let LOADING_TIMER = 1500;

  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, LOADING_TIMER);
  }, []);

  const animateLoadingPage = {
    width: "100%",
    height: "100%",
    color: "white",
    fontSize: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "roboto",
    backgroundColor: "rgba(0, 0, 0, 0.98)",
    /** set position fixed*/
    position: "absolute",
    top: "0",
    zIndex: "500",
    /** Click through div to underlying elements */
    pointerEvents: "none",
    opacity: showElement ? 5 : 0,
    animationName: "fade",
    animationDuration: "1.5s",
    transition: "all .5s",
    mozTransition: "all .5s",
    webkitTransition: "all .5s",
    oTransition: "all .5s",
    msTransition: "all .5s",
  };

  /*
  const handleMouseEnter = (e) => {
    e.target.classList.add("loading-page");
  }
  const handleTransitionEnd = (e) => {
    e.target.classList.remove("loading-page");
  }
  */

  /** ------------------------------------------------------ */

  let TITLE = "Cifor";
  let SUB_TITLE = "Bogor, Jawa Barat";

  return (
    <div className='detail-portofolio-section' id='id-detail-portofolio' ref={ref}>

      <div className='detail-portofolio-image-slider'>
        <div className='detail-portofolio-container'>
          <CiforImageSlider />
        </div>
      </div>

      <div className='header'>

        {/** disable content : belum siap diaplikasikan kunci tgl 14-09-24 10:25 */}
        {/**
        <div className='detail-portofolio-description'>
          <div className='detail-portofolio-nav-info-header'>
          <a>{/** konten isi untuk text header nama judul proyek}</a>
          </div>

          <div className='detail-portofolio-content-container'>
            <a className='detail-portofolio-text-description'>
               {/** konten isi untuk narasi penjelasan detail proyek}
            </a>
          </div>
        </div>
        */}
        <div>
          <Footer/>
        </div>

        {/** <div id="id-loading-page" onAnimationStart={handleMouseEnter} onAnimationEnd={handleTransitionEnd} style={animateLoadingPage}> */}
        {showElement ? (
          <div id="id-loading-page" style={animateLoadingPage}>
            <p><a className='loading-page-title'>{TITLE}<i>-HOUSE</i></a></p>
            <p><a className='loading-page-sub-title'>{SUB_TITLE}</a></p>
          </div>
        ) : (<div></div>)}

      </div>

      <FloatingBackToHome/>

    </div >
  );
}

export default AppPortofolioCiforHouse;