import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidAlAzharSlider from "./slider/MasjidAlAzharSlider";
import Footer from "../../../../../../Footer";

export default function MasjidAlAzhar() {

  return (
    <div className="modal-img">
        <MasjidAlAzharSlider/>
        <Footer/>
    </div>
  );
}