import React, { useState, useRef, useEffect, Fragment } from "react"
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"
import './ContactUs.css'

import iconMarker from "../assets/icon/white-map-marker-24px.png"
import iconEmail from "../assets/icon/white-mail-24px.png"
import iconPhone from "../assets/icon/white-phone-24px.png"

import linkMediaYoutube from "../assets/icon/info-youtube-logo-24px.png"
import linkMediaInstagram from "../assets/icon/info-instagram-24px.png"
import linkMediaFacebook from "../assets/icon/info-facebook-24px.png"
import linkMaps from "../assets/icon/icon-google-maps-48px.png"
import FloatingBackButton from "../interface/back_page_button/FloatingBackButton"
import FloatingBackToHome from "../interface/back_to_home/FloatingBackToHome"
import { Helmet } from "react-helmet"

function ContactUs() {

  /** single select on hover */
  function MouseOver(event) {
    event.target.style.background = '#2490E3';
  }

  function MouseOut(event) {
    event.target.style.background = "";
  }

  /** multiple select handle this situation using useState() */
  let [over, setOver] = React.useState(false);

  let buttonstyle = {
    backgroundColor: ''
  }

  if (over) {
    buttonstyle.backgroundColor = "#2490E3";
  }

  else {
    buttonstyle.backgroundColor = '';
  }

  /** text promp keterangan pada input nama depan */
  function prompFirstName() {
    var x = document.getElementById("id-text-promp-first-name");

    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  /** text promp keterangan pada input email */
  function prompEmail() {
    var x = document.getElementById("id-text-promp-email");

    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  /** text promp keterangan pada input nama terahir */
  function prompLastName() {
    var x = document.getElementById("id-text-promp-last-name");

    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  /** text promp keterangan pada input nomor hp */
  function prompPhoneNumber() {
    var x = document.getElementById("id-text-promp-phone-number");

    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  /** ----------------------------- sending email ------------------------------- */
  function sendEmail(event) {
    let ID_EditTextSubject = "id-input-subject";

    let ID_EditTextFirstName = "id-input-first-name";
    let ID_EditTextLastName = "id-input-last-name";
    let ID_EditTextEmail = "id-input-email";
    let ID_EditTextBody = "id-input-body";

    const inputEmail = encodeURIComponent(document.getElementById(ID_EditTextEmail).value);
    const inputBody = encodeURIComponent(document.getElementById(ID_EditTextBody).value);
    const inputFirstName = encodeURIComponent(document.getElementById(ID_EditTextFirstName).value);
    const inputLastName = encodeURIComponent(document.getElementById(ID_EditTextLastName).value);

    const composeInputToBody = `Nama : ${inputFirstName} ${inputLastName}, Email : ${inputEmail}, Pesan : [ ${inputBody} ]`;

    let EMAIL_TO = "rekanata.contractors@gmail.com";
    let EMAIL_CC = "";
    let EMAIL_BCC = "";
    let EMAIL_SUBJECT = "SUBJECT";
    let EMAIL_BODY_MESSAGE = composeInputToBody;

    const link = `mailto:${EMAIL_TO}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY_MESSAGE}&cc=${EMAIL_CC}&bcc=${EMAIL_BCC}`;
    window.location.href = link;
  }

  /* navigation on click */
  function openLink(urls) {
    window.open(urls);
  }

  /** navigation to from div clicked */
  const navigate = useNavigate();

  return (
    <form>
      <div className="contact-us fade">
        <Helmet>
          <title>Kontak Kami</title>
          <meta name="description" content="Kontak Kami Rekanata Konstruksi" />
          <link rel="canonical" href="https://rekanata.id/contact-us"/>
        </Helmet>

        <FloatingBackToHome />
        <div className="bg">

          <div class="bg-overlay" />
          <div class="container">
            <div className="base-header">
              <h2 className="contact-us-h2">Kontak Kami</h2>
              <div className="grid-container">

                <div className="contact-form">
                  <h1 className="contact-us-h1">Contact Form</h1>
                  <div className="input-container">

                    <div className="left-input">
                      <div className="child-item">
                        <div className="text-promp-input-area"><a>Nama Depan</a>
                          <a id="id-text-promp-first-name" className="text-promp">: (Wajib di isi)</a>
                        </div>
                        <input className="contact-us-input" type="text" autocomplete="off" id="id-input-first-name" name="firstname" placeholder="Nama Depan" onMouseEnter={prompFirstName} onMouseLeave={prompFirstName} />
                      </div>

                      <div className="child-item">
                        <div className="text-promp-input-area"><a>Nama Terahir</a>
                          <a id="id-text-promp-last-name" className="text-promp">: (Wajib di isi)</a>
                        </div>
                        <input className="contact-us-input" type="text" autocomplete="off" id="id-input-last-name" name="lastname" placeholder="Nama Terahir" onMouseEnter={prompLastName} onMouseLeave={prompLastName} />
                      </div>

                      <div className="child-item">
                        <div className="text-promp-input-area"><a>Email</a>
                          <a id="id-text-promp-email" className="text-promp">: (Wajib di isi)</a>
                        </div>
                        <input className="contact-us-input" type="text" autocomplete="off" id="id-input-email" name="email" placeholder="Email" onMouseEnter={prompEmail} onMouseLeave={prompEmail} />
                      </div>
                    </div>

                    <div className="right-input">
                    </div>

                  </div>

                  <div className="message-input">
                    <a>Email Subject</a>
                    <textarea className="contact-us-textarea" id="id-input-body" name="subject" placeholder="Email subject" />
                  </div>

                  <button className="button-send-message" onClick={(event) => sendEmail(event)}><a>Kirim</a></button>
                </div>

                <div className="info-form">
                  <div className="contact-info">
                    <h1 className="contact-us-h1">Contact Info</h1>
                    <div className="contact-info-container">

                      <div className="list-item">
                        <img src={iconMarker} className="list-icon" />
                        <a>Jalan Cimincrang, Kavling Pos Giro no 10, Cimenerang, Kec. Gedebage, Kota Bandung, Jawa Barat 40295 Indonesia.</a>
                      </div>

                      <div className="list-item">
                        <img src={iconEmail} className="list-icon" />
                        <a>rekanata.constractors@gmail.com</a>
                      </div>

                      <div className="list-item">
                        <img src={iconPhone} className="list-icon" />
                        <a>0821 1000 2640</a>
                      </div>

                      <div className="social-media-link">
                        <img
                          src={linkMaps}
                          className="mlink-icon"
                          onMouseOver={MouseOver}
                          onMouseOut={MouseOut}
                          onClick={() => { navigate('/our-location') }} />

                        <img
                          src={linkMediaYoutube}
                          className="mlink-icon"
                          onMouseOver={MouseOver}
                          onMouseOut={MouseOut}
                          onClick={() => openLink('https://www.youtube.com/channel/UCzOI07-Sd2PK7P9xW3-Dw5g/featured')} />

                        <img
                          src={linkMediaInstagram}
                          className="mlink-icon"
                          onMouseOver={MouseOver}
                          onMouseOut={MouseOut}
                          onClick={() => openLink('rekanata.contractors')} />

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ContactUs;
