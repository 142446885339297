import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/00_masjid_muhajirin_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/01_masjid_muhajirin.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/02_masjid_muhajirin.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/03_masjid_muhajirin.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/04_masjid_muhajirin.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/05_masjid_muhajirin.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/06_masjid_muhajirin.jpg";
import image7 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/07_masjid_muhajirin.jpg";
import image8 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/08_masjid_muhajirin.jpg";
import image9 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/09_masjid_muhajirin.jpg";
import image10 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/10_masjid_muhajirin.jpg";
import image11 from "../../../../../../../../assets/images/design_masjid/MASJID_MUHAJIRIN/11_masjid_muhajirin.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Muhajirin",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  },
  {
    title: "",
    description: design_description,
    urls: image5,
  },
  {
    title: "",
    description: design_description,
    urls: image6,
  },
  {
    title: "",
    description: design_description,
    urls: image7,
  },
  {
    title: "",
    description: design_description,
    urls: image8,
  },
  {
    title: "",
    description: design_description,
    urls: image9,
  },
  {
    title: "",
    description: design_description,
    urls: image10,
  },
  {
    title: "",
    description: design_description,
    urls: image11,
  },
];
