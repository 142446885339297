import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_SRAGEN/00_masjid_sragen_cover.jpg";


let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Sragen",
    description: design_description,
    urls: image0,
  }
];
