import React from "react";

function DetailPortofolioDots({ activeIndex, onclick, sliderImage }) {
  return (
    <div className="portofolio-all-dots">
      {sliderImage.map((slide, index) => (
        <span
          key={index}
          className={`${activeIndex === index ? "portofolio-dot portofolio-active-dot" : "m-dot"}`}
          onClick={() => onclick(index)}
        ></span>
      ))}
    </div>
  );
}

export default DetailPortofolioDots;
