import React, { useRef, useState, useEffect } from 'react'
import "./ProjectRumah.css"
import Footer from '../Footer'
import TabsProjectGallery from './tabs/TabsProjectGallery'
import Navbar2DarkBg from '../../interface/navbar/Navbar2DarkBg'
import { Helmet } from 'react-helmet'

function ProjectRumah() {

  let TAB_PROYEK_RUMAH = 1;

  return (
    <div className='app-project-rumah'>
      <div className='project-rumah-nav-header'>
        <Navbar2DarkBg/>
      </div>

      <div className='project-rumah-container'>
        <TabsProjectGallery page={TAB_PROYEK_RUMAH}/>
      </div>

      <div className='project-rumah-bottom-nav'>
        <Footer />
      </div>
    </div>
  )
}

export default ProjectRumah