import { Helmet } from 'react-helmet'
import FloatingWhatsApp from '../../../interface/floating_whatsapp/FloatingWhatsApp'
import Footer from '../../Footer'
import Testimonials from '../../Testimonials'
import './AppRekanata.css'
import HeroBackground from './pages/hero_background/HeroBackground'
import OurService from './pages/OurService'

function AppRekanata() {
    return (
        <div className='app-rekanata-section fade' id='id-app-rekanata-section'>
            <Helmet>
                <title>Tentang Kami Rekanata Konstruksi</title> {/** judul title di web browser*/}
                <meta name="description" content="Tentang Kami Rekanata Konstruksi" /> {/** munculkan sub link meta link di web browser*/}
                <link rel="canonical" href="https://rekanata.id/about-rekanata"/> {/** meta target link di web browser*/}
            </Helmet>

            <HeroBackground />

            {/**
             <About/>
            <OurService/>
            <Testimonials/>
             */}

            <FloatingWhatsApp />
            <Footer />
        </div>
    )
}

export default AppRekanata