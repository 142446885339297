import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_RAYA_BUMI_ORANGE/00_masjid_raya_bumi_orange_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_RAYA_BUMI_ORANGE/01_masjid_raya_bumi_orange.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_RAYA_BUMI_ORANGE/02_masjid_raya_bumi_orange.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Raya Bumi Orange",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  }
];
