import image1 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/portofolio-ant-house.jpg";
import image2 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/01-eksterior-ant-house-lt1-taman.jpg";
import image3 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/02-interior-ant-house-lt1-dapur-pantry.jpg";
import image4 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/03-interior-ant-house-lt1-dapur-pantry.jpg";
import image5 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/04-interior-ant-house-lt1-ruang keluarga.jpg";
import image6 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/05-interior-ant-house-lt1-ruang tamu.jpg";
import image7 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/06-interior-ant-house-lt2-ruang-keluarga.jpg";
import image8 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/07-interior-ant-house-lt2-toilet.jpg";
import image9 from "../../../../../assets/images/project/rumah/ANTO_HOUSE/08-interior-ant-house-lt2-ruang-keluarga-lampu-gantung.jpg";

export default [
  {
    title: "Ant House",
    description: "Rumah Industrial Modern Minimalis",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  },
  {
    title: "",
    description: "",
    urls: image5,
  },
  {
    title: "",
    description: "",
    urls: image6,
  },
  {
    title: "",
    description: "",
    urls: image7,
  },
  {
    title: "",
    description: "",
    urls: image8,
  },
  {
    title: "",
    description: "",
    urls: image9,
  }
];
