import img_cover from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/00_cover.jpg";
import img_01 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/01_cif.jpg";
import img_02 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/02_cif.jpg";
import img_03 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/03_cif.jpg";
import img_04 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/04_cif.jpg";
import img_05 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/05_cif.jpg";
import img_06 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/06_cif.jpg";
import img_07 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/07_cif.jpg";
import img_08 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/08_cif.jpg";
import img_09 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/09_cif.jpg";
import img_10 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/10_cif.jpg";
import img_11 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/11_cif.jpg";
import img_12 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/12_cif.jpg";
import img_13 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/13_cif.jpg";
import img_14 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/14_cif.jpg";
import img_15 from "../../../../../assets/images/project/rumah/CIFOR_HOUSE/15_cif.jpg";

export default [
  {
    title: "Cifor House",
    description: "Rumah Style Japandi",
    urls: img_cover,
  },
  {
    title: "",
    description: "",
    urls: img_01,
  },
  {
    title: "",
    description: "",
    urls: img_02,
  },
  {
    title: "",
    description: "",
    urls: img_03,
  },
  {
    title: "",
    description: "",
    urls: img_04,
  },
  {
    title: "",
    description: "",
    urls: img_05,
  },
  {
    title: "",
    description: "",
    urls: img_06,
  },
  {
    title: "",
    description: "",
    urls: img_07,
  },
  {
    title: "",
    description: "",
    urls: img_08,
  },
  {
    title: "",
    description: "",
    urls: img_09,
  },
  {
    title: "",
    description: "",
    urls: img_10,
  },
  {
    title: "",
    description: "",
    urls: img_11,
  },
  {
    title: "",
    description: "",
    urls: img_12,
  },
  {
    title: "",
    description: "",
    urls: img_13,
  },
  {
    title: "",
    description: "",
    urls: img_14,
  },
  {
    title: "",
    description: "",
    urls: img_15,
  },
];
