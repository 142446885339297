import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_LELA/00_masjid_lela_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_LELA/01_masjid_lela.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_LELA/masjid_lela_plan.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Lela",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  }
];
