import React from 'react'
import "./ProjectKomersial.css"
import Navbar2DarkBg from '../../interface/navbar/Navbar2DarkBg'
import Footer from '../Footer'
import TabsProjectGallery from './tabs/TabsProjectGallery'

function ProjectKomersial() {

  let TAB_KOMERSIAL = 4;

  return (

    <div className='app-project-komersial'>
      <div className='project-komersial-nav-header'>
        <Navbar2DarkBg/>
      </div>

      <div className='project-komersial-container'>
        <TabsProjectGallery page={TAB_KOMERSIAL}/>
      </div>

      <div className='project-komersial-bottom-nav'>
        <Footer/>
      </div>
    </div>
  )
}

export default ProjectKomersial