import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_ASY_SYIFA/00_masjid_asy_syifa_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_ASY_SYIFA/01_masjid_asy_syifa.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_ASY_SYIFA/02_masjid_asy_syifa.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_ASY_SYIFA/03_masjid_asy_syifa.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_ASY_SYIFA/04_masjid_asy_syifa.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Asy-Syifa",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  }
];
