import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/00_masjid_al_wushul_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/01_masjid_al_wushul.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/02_masjid_al_wushul.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/03_masjid_al_wushul.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/04_masjid_al_wushul.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/05_masjid_al_wushul.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_ALWUSHUL/06_masjid_al_wushul.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Al-Wushul",
    description: design_description,
    urls: image0,
  },
  {
    title: "Masjid Al-Wushul",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  },
  {
    title: "",
    description: "",
    urls: image5,
  },
  {
    title: "",
    description: "",
    urls: image6,
  }
];
