import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_DARUL_MUTTAQIN_BOJONEGORO/00_masjid_darul_muttaqin_bojonegoro_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_DARUL_MUTTAQIN_BOJONEGORO/masjid_darul_muttaqin_bojonegoro_plan.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Darul Muttaqin",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  }
];
