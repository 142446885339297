import React, { useRef, useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import '../components/css/ProjectGallery.css'
import AppFilterImageGallery from "../../../interface/filter_image_gallery/AppFilterImageGallery"

import ImageData from "./RumahImageData";
import { Helmet } from 'react-helmet';
import RumahFilter from './rumah_filter/RumahFilter';
import SmoothScrollToTop from '../../../interface/smooth_scroll_to_top/SmoothScrollToTop';

function Rumah() {

    /** navigation to from div clicked */
    const navigate = useNavigate();
    /** onClick={() => { navigate('/detail-project/fawn-house') }} */

    return (
        <div className="gallery-house fade">

            <div className="gallery-house-hero-image">
                <div className="gallery-house-hero-text">
                    <h1 className='gallery-house-h1'>PROYEK RUMAH</h1>
                </div>
            </div>

            {/** <AppFilterImageGallery/> */}
            {/** Include filter project gallery */}
            <RumahFilter/>
            <SmoothScrollToTop/>

            <div className="row-house">

                {/** RUMAH */}
                {/** DISABLE CONTENT (di arsipkan)
                <div className="column-house">
                    <div className="project-header" id="id-project-rumah">
                        <div className="project-container">
                            {ImageData.map((content) => (
                                <img src={content.AWILIGAR_HOUSE} alt="Thumb" className="image" />
                            ))}

                            <div className="project-overlay">
                                <div className='content-title'>
                                    <p id='myUL'>Awiligar House</p>
                                </div>

                                <div className='content-year'>
                                    <p>2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}

            </div>
        </div>
    )
}

export default Rumah