import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_PADANG/00_masjid_padang_cover.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_PADANG/01_masjid_padang.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_PADANG/02_masjid_padang.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_PADANG/03_masjid_padang.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_PADANG/masjid_padang_plan.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Padang",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: "",
    urls: image2,
  },
  {
    title: "",
    description: "",
    urls: image3,
  },
  {
    title: "",
    description: "",
    urls: image4,
  },
  {
    title: "",
    description: "",
    urls: image5,
  }
];
