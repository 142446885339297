import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/00_masjid_kalangsari_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/01_masjid_kalangsari.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/02_masjid_kalangsari.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/03_masjid_kalangsari.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/04_masjid_kalangsari.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/05_masjid_kalangsari.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/06_masjid_kalangsari.jpg";
import image7 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/07_masjid_kalangsari.jpg";
import image8 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/08_masjid_kalangsari.jpg";
import image9 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/09_masjid_kalangsari.jpg";
import image10 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/10_masjid_kalangsari.jpg";
import image11 from "../../../../../../../../assets/images/design_masjid/MASJID_KALANGSARI/11_masjid_kalangsari.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Kalang",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  },
  {
    title: "",
    description: design_description,
    urls: image5,
  },
  {
    title: "",
    description: design_description,
    urls: image6,
  },
  {
    title: "",
    description: design_description,
    urls: image7,
  },
  {
    title: "",
    description: design_description,
    urls: image8,
  },
  {
    title: "",
    description: design_description,
    urls: image9,
  },
  {
    title: "",
    description: design_description,
    urls: image10,
  },
  {
    title: "",
    description: design_description,
    urls: image11,
  }
];
