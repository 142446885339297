import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./SideNav.css";
import iconMenu from "../../../assets/icon/icon-menu-24px.png"
import iconCloseMenu from "../../../assets/icon/icon-close-24px.png"

function SideNav() {

  function myFunction() {
    document.getElementById("side-nav-dropdown").classList.toggle("side-nav-show")
  }

  window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('side-nav-show')) {
          openDropdown.classList.remove('side-nav-show');
        }
      }
    }
  }

  const closePanel = () => {
    document.getElementById("side-nav-dropdown").classList.toggle("side-nav-show")
  }

  const setUppercase = {
    textTransform: "uppercase",
    fontSize: "11px"
  }

  return (
    <div className="side-nav-section">
      <div className="side-nav-dropdown-container">

        <div className="side-nav-dropdown-left-container">
          
          <button className="side-nav-dropbtn" onClick={myFunction}>
            <img src={iconMenu} className="side-nav-icon-menu" />
          </button>

          <div className="side-nav-dropdown-content" id="side-nav-dropdown" onClick={closePanel}>
            <div className="side-nav-dropdown-content-menu-item">
              <Link to="/" className="side-nav-link-text-item"><a>Halaman utama</a></Link>
              <Link to="/house-project" className="side-nav-link-text-item"><a>Proyek rumah</a></Link>
              <Link to="/interior-project" className="side-nav-link-text-item"><a>Proyek interior</a></Link>
              <Link to="/masterplan-project" className="side-nav-link-text-item"><a>Proyek masterplan</a></Link>
              <Link to="/commercial-project" className="side-nav-link-text-item"><a>Proyek komersial</a></Link>
              <Link to="/mosque-project" className="side-nav-link-text-item"><a>Proyek masjid</a></Link>
              <Link to="/free-design" className="side-nav-link-text-item"><a>Free Design</a></Link>
              <Link to="/blog" className="side-nav-link-text-item"><a>Blog kami</a></Link>
              <Link to="/about-rekanata" className="side-nav-link-text-item"><a>About</a></Link>
              <Link to="/service" className="side-nav-link-text-item"><a>Service</a></Link>
              <Link to="/contact-us" className="side-nav-link-text-item"><a>Contact Us</a></Link>
            </div>
            <div className="side-nav-dropdown-right-container">
              <img src={iconCloseMenu} className="side-nav-icon-close-menu" onClick={() => closePanel} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default SideNav;
