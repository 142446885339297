import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/00_masjid_pondok_pinang_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/01_masjid_pondok_pinang.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/02_masjid_pondok_pinang.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/03_masjid_pondok_pinang.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/04_masjid_pondok_pinang.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/05_masjid_pondok_pinang.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/06_masjid_pondok_pinang.jpg";
import image7 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/07_masjid_pondok_pinang.jpg";
import image8 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/08_masjid_pondok_pinang.jpg";
import image9 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/09_masjid_pondok_pinang.jpg";
import image10 from "../../../../../../../../assets/images/design_masjid/MASJID_PONDOK_PINANG/10_masjid_pondok_pinang.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Pondok Pinang",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  },
  {
    title: "",
    description: design_description,
    urls: image5,
  },
  {
    title: "",
    description: design_description,
    urls: image6,
  },
  {
    title: "",
    description: design_description,
    urls: image7,
  },
  {
    title: "",
    description: design_description,
    urls: image8,
  },
  {
    title: "",
    description: design_description,
    urls: image9,
  },
  {
    title: "",
    description: design_description,
    urls: image10,
  }
];
