import './OurServiceDetailView.css'
import imageMasterplan from '../../../../../../assets/images/project/killerimage/01.jpg'

function Masterplan() {
    return (
        <div className='our-service-view-detail-section' id='id-our-service-view-detail-section'>
            <div className='our-service-view-detail-container'>
                <div className='our-service-view-detail-container-grid'>

                    <div className='our-service-view-detail-left-row'>
                        <a className='our-service-view-detail-text-header'>
                            Masterplan
                        </a>
                        
                        <a className='our-service-view-detail-text-item'>
                        Wujudkan visi pembangunan Anda dengan jasa perancangan masterplan yang terintegrasi dan profesional dari tim ahli kami!.
                        Kami menyediakan solusi perancangan masterplan yang efektif, memastikan setiap aspek pembangunan terencana dengan baik.
                        Dari konsep hingga implementasi, kami membantu merancang masterplan yang mencakup semua kebutuhan dan aspirasi proyek Anda.
                        Dengan pendekatan yang berbasis analisis dan desain, kami menciptakan masterplan yang mendukung pertumbuhan dan keberlanjutan.
                        Kami ahli dalam merancang masterplan untuk berbagai proyek, mulai dari kawasan residensial hingga area komersial.
                        Konsultasikan ide pembangunan Anda dengan kami! Kami siap memberikan solusi perancangan masterplan yang sesuai dengan visi Anda.
                        Maksimalkan potensi lahan Anda dengan perancangan masterplan yang cerdas dan strategis. Hubungi kami untuk informasi lebih lanjut!.

                        {/** 
                        Wujudkan taman impian Anda dengan jasa lanskap dan masterplan profesional kami, yang mengutamakan keindahan dan keberlanjutan!
                        Kami ahli dalam merancang lanskap yang harmonis dan fungsional, menciptakan ruang luar yang dapat dinikmati oleh seluruh keluarga.
                        Dari perencanaan masterplan hingga eksekusi, kami memberikan solusi lanskap yang sesuai dengan visi dan kebutuhan Anda.
                        Tim kami siap membantu merancang ruang luar yang menarik dan ramah lingkungan, meningkatkan nilai estetika properti Anda.
                        Dengan pendekatan yang terintegrasi, kami menciptakan lanskap yang indah dan berkelanjutan, sesuai dengan karakter lahan Anda.
                        Konsultasikan ide lanskap Anda dengan kami! Kami menyediakan layanan desain masterplan untuk berbagai jenis proyek.
                        Dapatkan ruang luar yang memukau dengan desain lanskap yang tepat! Hubungi kami untuk solusi yang sesuai dengan anggaran Anda.
                        */}
                        </a>

                    </div>

                    <div className='our-service-view-detail-right-row'>
                        <img src={imageMasterplan} className='our-service-view-detail-image' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Masterplan