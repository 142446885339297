import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_MIFTAHURRAHMAN_BOGOR/00_masjid_miftahurrahmah_bogor_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_MIFTAHURRAHMAN_BOGOR/01_masjid_miftahurrahmah_bogor.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_MIFTAHURRAHMAN_BOGOR/02_masjid_miftahurrahmah_bogor.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Miftahurrahmah",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  }
];
