import { useRef, useEffect } from 'react';
import './HeroBackground.css'
import Navbar2 from '../../../../../interface/navbar/Navbar2';

function HeroBackground() {

    const openYoutubeUrl = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='hero-background-rn-section' id='id-hero-background-rn-section'>
            <div className="hero-background-rn-image">
                <Navbar2 />

                <div className="hero-background-rn-hero-text">
                    <div className='hero-background-rn-center'>
                        <div className='hero-background-rn-center-floating-nav'>

                        </div>

                        <button id='id-hero-background-rn-button' className='hero-background-rn-button-action' onClick={() => openYoutubeUrl('https://www.youtube.com/@rekanata.contractors')}>Proyek Kami</button>

                        <h1></h1>
                        <p id='id-hero-background-rn-text' className='hero-background-rn-intro'>
                            Memulai bisnis konstruksi adalah cara yang bagus untuk membangun dan menciptakan gedung dan rumah.
                            Melalui bisnis ini, Anda tidak melakukan apa pun selain membuat orang tersenyum sambil membangun rumah dan kantor Anda.
                        </p>
                        <p id='id-hero-background-rn-text' className='hero-background-rn-text-opacity'>Membanguan rumah impian kini lebih mudah dengan jasa kami</p>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default HeroBackground