import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidLelaSlider from "./slider/MasjidLelaSlider";
import Footer from "../../../../../../Footer";

export default function MasjidLela() {

  return (
    <div className="modal-img">
        <MasjidLelaSlider/>
        <Footer/>
    </div>
  );
}