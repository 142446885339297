import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_PACET/00_masjid_pacet_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_PACET/01_masjid_pacet.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_PACET/02_masjid_pacet.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_PACET/03_masjid_pacet.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_PACET/04_masjid_pacet.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Pacet",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  }
];
