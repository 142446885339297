import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/00_masjid_kertayasa_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/01_masjid_kertayasa.jpg";
import image2 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/02_masjid_kertayasa.jpg";
import image3 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/03_masjid_kertayasa.jpg";
import image4 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/04_masjid_kertayasa.jpg";
import image5 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/05_masjid_kertayasa.jpg";
import image6 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/06_masjid_kertayasa.jpg";
import image7 from "../../../../../../../../assets/images/design_masjid/MASJID_KERTAYASA/07_masjid_kertayasa.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Kertayasa",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: design_description,
    urls: image1,
  },
  {
    title: "",
    description: design_description,
    urls: image2,
  },
  {
    title: "",
    description: design_description,
    urls: image3,
  },
  {
    title: "",
    description: design_description,
    urls: image4,
  },
  {
    title: "",
    description: design_description,
    urls: image5,
  },
  {
    title: "",
    description: design_description,
    urls: image6,
  },
  {
    title: "",
    description: design_description,
    urls: image7,
  }
];
