import './ExampleBlog1.css'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"

import thumbImage from "../../../../assets/images/project/rumah/AWILIGAR_HOUSE/awiligar-house.jpg"
import SlidedownNavbar from '../../../navbar/slide_down_navbar/SlideDownNavbar'

function ExampleBlog1() {

    let CATEGORY_POST = "Konstruksi"
    let CATEGORY_DATE_POST = "30 Novenmber 2022"
    let CATEGORY_CREATED_POST = "Admin"

    return (
        <div className="detail-blog-section">

            <div className='detail-blog-container'>
                <SlidedownNavbar/>
               
                <h1>My Blog</h1>
                <p>Content</p>
                <div className='detail-blog-image-container'>
                    <img src={thumbImage} className='detail-blog-big-image' />

                    <div className='detail-blog-image-footer'>
                        <a>Kategori: {CATEGORY_POST}</a>
                        <a>By: {CATEGORY_CREATED_POST}</a>
                        <a>Tanggal: {CATEGORY_DATE_POST}</a>
                    </div>
                </div>

                <div className='detail-blog-text-description'>
                    <p>
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        valuev
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        valuev
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        valuev
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        valuev
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        valuev
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.

                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                        Masterplan untuk kawasan perumahan, perkantoran, kompleks olahraga,
                        outbond sampai dengan perencanaan kota.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default ExampleBlog1