import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import './Footer.css'

import fabInstagram from '../../components/assets/icon/menu-instagram-24px.png'
import fabYoutube from '../../components/assets/icon/menu-youtube-24px.png'

import fabCallUs from '../../components/assets/icon/icon-call-32px.png'
import fabEmail from '../../components/assets/icon/icon-gmail-32px.png'
import fabMapLocation from '../../components/assets/icon/map-marker-24px.png'
import fabWhatsAppBussines from '../../components/assets/icon/icon-whatsapp-24px.png'
import fabCompany from '../../components/assets/images/logo/logo_rekanata_200x37px-white.png'

function Footer() {

	let PHONE_NUMBER = 'T. 0821 1000 2640'
	let EMAIL_ADDRESS = 'E. rekanata.contractors@gmail.com'

	const d = new Date()
	let year = d.getFullYear()

	/* navigation on click */
	const instagramLink = (profile_page) => {
		window.open(`http://instagram.com/_u/${profile_page}/`);
	}

	const youtubeLink = (youtube_fetured_link) => {
		window.open(`${youtube_fetured_link}`);
	}

	let phonenumber = "+6282110002640";
	let tamplateTextMessage = "";

	const whatsAppBussinesMobile = () => {
		window.open('whatsapp://send?text=https://www.youtube.com/watch?v=ohpCMpderow');
	}

	const whatsAppApi = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
		if (newWindow) newWindow.opener = null
	}

	const whatsAppBussines = (url) => {
		return () => whatsAppApi(url)
	}

	return (
		<div className="footer" id="id-footer">
			<div className='footer-container'>
				<div className='footer-top-container'>
					<div className='footer-row'>
						<div>
							<a className='footer-text'>
								<p className='title-header'>Contact Us</p>
							</a>

							<div className='footer-list-with-image'>
								<img src={fabCallUs} className="footer-fab-list-image" />
								<a className='footer-text-info'>{PHONE_NUMBER}</a>
							</div>

							<div className='footer-list-with-image'>
								<img src={fabEmail} className="footer-fab-list-image" />
								<a className='footer-text-info'>{EMAIL_ADDRESS}</a>
							</div>
						</div>
					</div>

					<div className='footer-row'>
						<a className='footer-text'>
							<p className='title-header'>Our Service</p>
							<p><Link to='/service-detail:id/design-and-build' className='footer-text-link'>Desain and Build</Link></p>
							<p><Link to='/service-detail:id/renovasi' className='footer-text-link'>Renovasi Bangunan</Link></p>
							<p><Link to='/service-detail:id/interior-design' className='footer-text-link'>Interior - Desain and Build </Link></p>
							<p><Link to='/service-detail:id/masterplan' className='footer-text-link'>Masterplan - Landscape Taman</Link></p>
							<p><Link to='/service-detail:id/masjid' className='footer-text-link'>Free Design Masjid</Link></p>
							<br />
							<a className='footer-text'>
								<p className='title-header'>WhatsApp Bussines</p>
							</a>
							<p><Link className='footer-text-link' onClick={whatsAppBussines(`https://wa.me/${phonenumber}?text=${tamplateTextMessage}`)}>Informasi lebih lanjut</Link></p>
						</a>
					</div>

					<div className='footer-row'>
						<a className='footer-text'>
							<p className='title-header'>Our Location</p>
							<p className="footer-text-link">Jl. Cimincrang,
								Kavling Pos Giro no 10,
								Cimenerang,
								Kec. Gedebage,
								Kota Bandung,
								Jawa Barat 40295,
								Indonesia.
							</p>
							<br />
							<a className='footer-text'>
								<p className='title-header'>Lokasi</p>
							</a>
							<div className='footer-list-with-image'>
								<img src={fabMapLocation} className="footer-fab-list-image" />
								<a className='footer-text-info'><Link to='/our-location' className='footer-text-link'>Lihat Lokasi Kami di Map</Link></a>
							</div>
						</a>
					</div>

					<div className='footer-row'>
						<a className='footer-text'>
							<p className='title-header'>Subscribe Us</p>
							<p className="footer-text-link">Solusi untuk mendapatkan  rumah yang lebih nyaman, lebih luas dan lebih sehat.
								Kami analisa rumah lama sehingga dapatkan desain dan build yang inovatif,  efektif dan efisien.
							</p>
						</a>
						<a className='footer-text'>
							<p className='title-header'>Social media</p>
						</a>
						<div className="footer-social-links">
						    <div className='footer-list-with-image' onClick={() => instagramLink('rekanata_konstruksi')}>
						        <img src={fabInstagram} className="footer-fab-icon" />
								<a className='footer-text-info'>Instagram</a>
							</div>
				
							<div className='footer-list-with-image' onClick={() => youtubeLink('https://www.youtube.com/channel/UCzOI07-Sd2PK7P9xW3-Dw5g/featured')}>
							<img src={fabYoutube} className="footer-fab-icon" />
								<a className='footer-text-info'>YouTube</a>
							</div>
						</div>
					</div>
				</div>

				<div className='footer-bottom-container'>
					<div className='f-bottom-left'>
						<a className='footer-text'>
							<p>Copyright &copy; 2021 - {year} REKANATA - All Rights Reserved.</p>
						</a>
					</div>

					<div className='f-bottom-right'>
						
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
