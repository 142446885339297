import { GalleryData } from "./GalleryData";
import { useEffect, useState } from "react";
import './AppFilterImageGallery.css';

function AppFilterImageGallery() {

  const [data, setData] = useState([]);
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    setData(GalleryData);
    setCollection([... new Set(GalleryData.map((item) => item.tile))])
  }, [])

  const gallery_filter = (itemData) => {
    const filterData = GalleryData.filter((item) => item.tile == itemData);
    setData(filterData);
  }

  return (
    <div className="filter-image-gallery-section">
      <div className="galleryWrapper">
        <div className="filterItem">
          <ul>
            <li><button onClick={() => setData(GalleryData)}>All</button></li>
            {
              collection.map((item) => <li><button onClick={() => { gallery_filter(item) }}>{item}</button></li>)
            }
          </ul>
        </div>
        <div className="galleryContainer">
          {
            data.map((item) => <div key={item.id} className="galleryItem"><img src={item.image} /></div>)
          }
        </div>
      </div>
    </div>
  );
}

export default AppFilterImageGallery;