import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidSukamanahSlider from "./slider/MasjidSukamanahSlider";
import Footer from "../../../../../../Footer";

export default function MasjidSukamanah() {

  return (
    <div className="modal-img">
        <MasjidSukamanahSlider/>
        <Footer/>
    </div>
  );
}