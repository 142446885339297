import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidPacetSlider from "./slider/MasjidPacetSlider";
import Footer from "../../../../../../Footer";

export default function MasjidPacet() {

  return (
    <div className="modal-img">
        <MasjidPacetSlider/>
        <Footer/>
    </div>
  );
}