import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidAcehSlider from "./slider/MasjidAcehSlider";

export default function MasjidAceh() {

  return (
    <div className="modal-img">
        <MasjidAcehSlider/>
    </div>
  );
}