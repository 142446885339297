import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidUjungGentengSlider from "./slider/MasjidUjungGentengSlider";
import Footer from "../../../../../../Footer";

export default function MasjidUjungGenteng() {

  return (
    <div className="modal-img">
        <MasjidUjungGentengSlider/>
        <Footer/>
    </div>
  );
}