import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidAsySyifaSlider from "./slider/MasjidAsySyifaSlider";
import Footer from "../../../../../../Footer";

export default function MasjidAsySyifa() {

  return (
    <div className="modal-img">
        <MasjidAsySyifaSlider/>
        <Footer/>
    </div>
  );
}