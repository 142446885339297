let DAB = "Design And Build"
let RENOV = "Renovasi"
let STR = "Konstruksi"

export default [
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/AWILIGAR_HOUSE/awiligar-house.jpg'),
        setTagInfo: DAB,
        setTitle: "AWILIGAR House",
        setDescription:
        `Jl. Awiligar Raya, Kabupaten Bandung, Jawa Barat`,
        setNavigate: "/detail-project/portofolio-awiligar-house",
        setCompletedProject: "2020"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/GW_HOUSE/grand-wisata.jpg'),
        setTagInfo: DAB,
        setTitle: "GW House",
        setDescription:
        `Jl. Celebration Boulevard, Kabupaten Bekasi, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2016"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/FAWN_HOUSE/fawn-house.jpg'),
        setTagInfo: DAB,
        setTitle: "FAWN House",
        setDescription:
        `Jl. Cimincrang, Gedebage, Kota Bandung, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2020"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/TRA_HOUSE/tarra-house.jpg'),
        setTagInfo: DAB,
        setTitle: "TRA House",
        setDescription:
        `Jl. Cifor, Kp.bubulak, Bogor, Kota Bogor`,
        setNavigate: "",
        setCompletedProject: "2017"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/YTM_HOUSE/yatim-house.jpg'),
        setTagInfo: DAB,
        setTitle: "YTM House",
        setDescription: `Jl. Buahbatu, Kota Bandung`,
        setNavigate: "",
        setCompletedProject: "2021"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/YDI_HOUSE/rumah_ydi_house.jpg'),
        setTagInfo: DAB,
        setTitle: "YDI House",
        setDescription: `Depok, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2019"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/DOD_HOUSE/rumah-dod-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "DOD House",
        setDescription: `Kota Bogor, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2018"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/KIARAPAYUNG_HOUSE/rumah-kiarapayung-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "BUMI KIARAPAYUNG",
        setDescription: `Perumahan bumi kiarapayung, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2016"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/MEI_HOUSE/rumah-mei-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "MEI House",
        setDescription: `Kota Bandung, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2019"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/BUMI_KIRPAY/rumah-bumi-kiarapayung-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "BUMI KIARAPAYUNG",
        setDescription: `Perumahan bumi kiarapayung, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2016"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/JATI_HANDAP_HOUSE/rumah-jati-handap-thumb.jpg'),
        setTagInfo: RENOV,
        setTitle: "JATI HANDAP House",
        setDescription: `Jl. Jatihandap, Kec. Mandalajati, Kota Bandung, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2018"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/INHOFTANK_HOUSE/rumah-inhoftank-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "INHOFTANK House",
        setDescription: `Jl. Inhoftank Komp. Jati Permai, Kota Bandung`,
        setNavigate: "",
        setCompletedProject: "2018"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/RYN_HOUSE/rumah-ryn-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "RYN House",
        setDescription: `Bogor, Bubulak, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2018"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/RZ_HOUSE/rumah-rz-thumb.jpg'),
        setTagInfo: RENOV,
        setTitle: "RZ House",
        setDescription: `Bandung Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2018"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/YNI_HOUSE/rumah-yni-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "YNI House",
        setDescription: `Jl. BKR, Kota Bandung, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2022"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/OD_HOUSE/rumah-od-house.jpg'),
        setTagInfo: DAB,
        setTitle: "OD House",
        setDescription: `Bogor Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2019"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/HRS_HOUSE/rumah-hrs-thumb.jpg'),
        setTagInfo: DAB,
        setTitle: "HRS House",
        setDescription: `Kota Bandung Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2020"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/ANTO_HOUSE/01-thumb-Anto House.jpg'),
        setTagInfo: RENOV,
        setTitle: "ANT House",
        setDescription: `Kota Bandung Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2023"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/CIFOR_HOUSE/00_cover.jpg'),
        setTagInfo: RENOV,
        setTitle: "CIFOR House",
        setDescription:
        `Jl. Raya Cifor, Bogor, Jawa Barat`,
        setNavigate: "/detail-project/portofolio-cifor-house",
        setCompletedProject: "2024"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/RUKOST/rukost-ciputat-01.jpg'),
        setTagInfo: DAB,
        setTitle: "RUKOST",
        setDescription:
        `Jl. Raya Tapos, Depok, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2020"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/RUKOST/rukost-ciputat-02.jpg'),
        setTagInfo: DAB,
        setTitle: "RUKOST",
        setDescription:
        `Jl. Raya Tapos, Depok, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2020"
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/rumah/HNS_PIK2_HOUSE/00_pik2_cover.jpg'),
        setTagInfo: DAB,
        setTitle: "HNS House - Rumah Kontemporer",
        setDescription:
        `Pantai Indah Kapuk 2, PIK2, Jakarta Utara`,
        setNavigate: "",
        setCompletedProject: "2024"
    },

    /** OFFICE */
    {
        setId: 2,
        setImage: require('../../../../assets/images/project/rumah/WSM_OFFICE/kantor-wsm-thumb.jpg'),
        setTagInfo: RENOV,
        setTitle: "WSM Office",
        setDescription: 
        `Kota Depok, Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2022"
    },
    {
        setId: 2,
        setImage: require('../../../../assets/images/project/rumah/GUMMI_OFFICE/eksterior_gummi_office_facade.jpg'),
        setTagInfo: RENOV,
        setTitle: "GMI Office",
        setDescription: 
        `Kota Bandung Jawa Barat`,
        setNavigate: "",
        setCompletedProject: "2022"
    }
]

/** setNavigate: "/penggunaan-bahan-material" */