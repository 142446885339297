import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom"
import React from "react";
import floatingIcon from "../../assets/icon/icon-whatsapp-48px.png";
import menuContact from "../../assets/icon/menu-contact-24px.png";
import menuGmail from "../../assets/icon/menu-gmail-logo-24px.png";
import menuInstagram from "../../assets/icon/menu-instagram-24px.png";
import menuYoutube from "../../assets/icon/menu-youtube-24px.png";
import './FloatingWhatsApp.css';
import ListViewitemContainer from "./ListViewItemContainer";

function FloatingWhatsApp() {

    /** detect mobile device using java script */
    /*
    const WindowInterfaceScreenSize = (event) => {
        if (window.innerWidth >= 480) {

        }
    }
    */

    /*
    const mq = window.matchMedia("(min-width: 500px)");
    if (mq.matches) {
        // window width is at least 500px
    } else {
        // window width is less than 500px
    }
    */

    /** send mail */
    let USERNAME_INSTAGRAM_PROFILE = "rekanata_konstruksi";

    /** hide and show Floating WhatsApp from event click */
    const toggleFloatingWhatsApp = () => {
        var mFloating = document.getElementById("id-content");

        
        if (mFloating.style.display === "block") {
            mFloating.style.display = "none";
        } else {
            mFloating.style.display = "block";
        }

        var mIcon = document.getElementById("id-whatsapp-icon");
        if (mIcon.style.display === "none") {
            mIcon.style.display = "block";
        } else {
            mIcon.style.display = "none";
        }
    }

    /** navigation to from div clicked */
    const navigate = useNavigate();

    const toggleOpenContactMe = () => {
        /** close panel floating wahatsapp */
        toggleFloatingWhatsApp();
        navigate('/contact-us')
    }

    const toggleOpenEmail = (e) => {
        /** close panel floating wahatsapp */
        toggleFloatingWhatsApp();

        /** Send email from web browser
        https://mail.google.com/mail/?view=cm&fs=1&to=<to_address>&su=<subject>
        http://compose.mail.yahoo.com/?to=<to_address>&subject=<subject>
        http://mail.live.com/mail/EditMessageLight.aspx?n=&to=<to_address>&subject=<subject>
        http://webmail.aol.com/Mail/ComposeMessage.aspx?to=<to_address>&subject=<subject>
        */

        /** Simple Compose Link : Opens the inbox with a compose window in the bottom right */
        /**
         Default account:
         https://mail.google.com/mail/#compose
         
         Specific account by index (second in this example):
         https://mail.google.com/mail/u/1/#compose

         Specific account by email address (lee@aexample.org in this example):
         https://mail.google.com/mail/u/lee@aexample.org/#compose

         Any account from specific domain (example.edu in this example):
         https://mail.google.com/a/example.edu/mail/#compose

         From specific delegated mailbox (id abcd1234efgh5678 in this example):
         https://mail.google.com/mail/d/abcd1234efgh5678/#compose

         From specific delegated mailbox belonging to non-default account (id 1234efgh5678ijkl, fourth user):
         https://mail.google.com/mail/u/3/d/1234efgh5678ijkl/#compose
        */

        /** let COMPOSE_EMAIL = "https://mail.google.com/mail/#compose"; */

        let EMAIL_TO = "rekanata.contractors@gmail.com";
        let EMAIL_SUBJECT = "SUBJECT";
        let EMAIL_BODY_MESSAGE = "";
        let EMAIL_BCC = "";

        let MAIL_TO_SUBJECT = ["mailto:" + { EMAIL_TO } + "?Subject=" + { EMAIL_SUBJECT }];
        // secondary way 
        let MAIL_TO = "https://mail.google.com/mail/?view=cm&fs=1&to=" + EMAIL_TO + "&su=" + EMAIL_SUBJECT + "&body=" + EMAIL_BODY_MESSAGE + "&bcc=" + EMAIL_BCC;
        window.open(`${MAIL_TO}`);


        /** compose email 
        e.preventDefault()
        const message = encodeURIComponent(document.getElementById('message').value)
        Email.send({
            Host : "smtp.yourisp.com",
            Username : "username",
            Password : "password",
            To : 'them@website.com',
            From : "you@isp.com",
            Subject : encodeURIComponent("This is my subject"),
            Body : message
        }).then(
          message => alert(message)
        ).catch( err => {
            alert(err)
        });
        */
    }

    const toggleOpenInstagram = (event, profile_page) => {
        // refers to the image element
        /** https://www.instagram.com/rekanata.contractors/ */

        console.log(event.target);
        console.log(profile_page);
        console.log('open.@link$-instagram.success()');

        window.open(`http://instagram.com/_u/${profile_page}/`);
    };

    const toggleOpenYoutube = () => {
        /** close panel floating wahatsapp */
        toggleFloatingWhatsApp();
        /** link old version */
        /** let FEATURED_YOUTUBE_HOME = "https://www.youtube.com/channel/UCzOI07-Sd2PK7P9xW3-Dw5g/featured"; */
        let FEATURED_YOUTUBE_HOME = "https://www.youtube.com/@rekanata.contractors";

        window.open(`${FEATURED_YOUTUBE_HOME}`);
    }

    /** check if application installed from web */

    return (
        <div className="floating-whatsapp" id="id-floating-whatsapp">
            <div className="floating-whatsapp-container">
                <div className="floating-whatsapp-content" id="id-content">

                    <div className="chat-container">
                        <div className="scrollview-y">
                            <p>
                                <div className="chat-activity">
                                    <div className="close-panel" onClick={toggleFloatingWhatsApp}><a>tutup jendela</a></div>
                                </div>
                                <div className="chat-activity">
                                    <ListViewitemContainer />
                                </div>

                            </p>
                        </div>

                        <div className="menu-container">

                            <p><a className="connect-with-us">Terhubung dengan kami</a></p>

                            <div className="horizontal-layout-menu">
                                <div className="box-menu-item">
                                    <div className="component-i">
                                        <p><a>Contact</a></p>
                                        <img src={menuContact} className="icon-menu" alt="contactOnPage" onClick={(event) => toggleOpenContactMe(event, USERNAME_INSTAGRAM_PROFILE)} />
                                    </div>
                                </div>

                                <div className="box-menu-item">
                                    <div className="component-i">
                                        <p><a>Instagram</a></p>
                                        <img src={menuInstagram} className="icon-menu" alt="instagramOnPage" onClick={(event) => toggleOpenInstagram(event, USERNAME_INSTAGRAM_PROFILE)} />
                                    </div>
                                </div>

                                <div className="box-menu-item">
                                    <div className="component-i">
                                        <p><a>Email</a></p>
                                        <img src={menuGmail} className="icon-menu" alt="emailOnPage" onClick={(event) => toggleOpenEmail(event, USERNAME_INSTAGRAM_PROFILE)} />
                                    </div>
                                </div>

                                <div className="box-menu-item">
                                    <div className="component-i">
                                        <p><a>Youtube</a></p>
                                        <img src={menuYoutube} className="icon-menu" alt="youtubeOnPage" onClick={(event) => toggleOpenYoutube(event, USERNAME_INSTAGRAM_PROFILE)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="floating-icon-container">
                    <div className="whatsapp-icon">
                        <img src={floatingIcon} className='whatsapp-icon-img' onClick={toggleFloatingWhatsApp} id="id-whatsapp-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FloatingWhatsApp;