export default [
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/ANT_HOUSE/01-interior-ant-house-lt1-ruang-keluarga.jpg'),
        setTagInfo: "Interior",
        setTitle: "ANT House",
        setDescription: `Bandung`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/ELN_HOUSE/interior-rumah-eln-thumb.jpg'),
        setTagInfo: "Interior",
        setTitle: "ELN House",
        setDescription: `Bogor`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/OD_HOUSE/interior-rumah-od-thumb.jpg'),
        setTagInfo: "Interior",
        setTitle: "OD House",
        setDescription: `Bogor`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/FZ_PNF/interior-fz-pnf-thumb.jpg'),
        setTagInfo: "Interior",
        setTitle: "FZ House",
        setDescription: `Depok`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/GUMMI_OFFICE/interior_gummi_office_open_space_4.jpg'),
        setTagInfo: "Interior",
        setTitle: "GMI House",
        setDescription: `Bandung`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/MAF_SD/interior-maf-sd-thumb.jpg'),
        setTagInfo: "Interior",
        setTitle: "MAF SD",
        setDescription: `Bogor`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/PARAHYANGAN_RESIDENCE/interior-parahyangan-residence-thumb.jpg'),
        setTagInfo: "Interior",
        setTitle: "PARAHYANGAN RESIDENCE",
        setDescription: `Bandung`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/PAYTRN_OFFICE/interior-paytrn-thumb.jpg'),
        setTagInfo: "Interior",
        setTitle: "PAYTR",
        setDescription: `Bandung`,
        setNavigate: "null",
        setCompletedProject: ""
    },
    {
        setId: 1,
        setImage: require('../../../../assets/images/project/interior/AGA_TASIK/interior-aga-tasik.jpg'),
        setTagInfo: "Interior",
        setTitle: "AGA Tasik",
        setDescription: `Tasik`,
        setNavigate: "null",
        setCompletedProject: ""
    },
]