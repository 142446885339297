import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidPadangSlider from "./slider/MasjidPadangSlider";
import Footer from "../../../../../../Footer";

export default function MasjiPadang() {

  return (
    <div className="modal-img">
        <div className="modal-container-img">
            <MasjidPadangSlider/>
        </div>
        <Footer/>
    </div>
  );
}