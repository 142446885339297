import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidPondokPinangSlider from "./slider/MasjidPondokPinangSlider";
import Footer from "../../../../../../Footer";

export default function MasjidPondokPinang() {

  return (
    <div className="modal-img">
        <MasjidPondokPinangSlider/>
        <Footer/>
    </div>
  );
}