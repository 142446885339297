import image0 from "../../../../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_2/00_masjid_cordova_cilodong_2_cover.jpg";
import image1 from "../../../../../../../../assets/images/design_masjid/MASJID_CORDOVA_CILODONG/Design_2/masjid_cordova_cilodong_2_plan.jpg";

let design_description = "Design Masjid"

export default [
  {
    title: "Masjid Cordova",
    description: design_description,
    urls: image0,
  },
  {
    title: "",
    description: "",
    urls: image1,
  }
];
