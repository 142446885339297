import React from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // You can use any icon library you prefer
import './FloatingBackButton.css';

const FloatingBackButton = () => {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <button className="floating-back-button" onClick={handleBack}>
      <FaArrowLeft className="back-icon" />
    </button>
  );
};

export default FloatingBackButton;