import React, { useState } from "react";
import "../css/ModalPopUp.css";
import MasjidKalangsariSlider from "./slider/MasjidKalangsariSlider";
import Footer from "../../../../../../Footer";

export default function MasjidKalang() {

  return (
    <div className="modal-img">
        <MasjidKalangsariSlider/>
        <Footer/>
    </div>
  );
}