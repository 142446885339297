import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './Navbar2.css';
import whiteLogo from "../../assets/images/logo/logo_rekanata_200x37px-white.png";
import coloredLogo from "../../assets/images/logo/logo_rekanata_200x37px-dark.png";
import SideNav from "./sidenav/SideNav";

function Navbar2() {

  let DISTANCE_SCROLL_DOWN = 50;

  const [dropdown, setDropdown] = useState(false);

  /** mengganti warna navbar ketika scroll kebawah */
  /*
  const navbar = document.querySelector('.navbar-2');

  window.onscroll = () => {
    if (window.scrollY > DISTANCE_SCROLL_DOWN) {
      navbar.classList.add('navbar-2-header-scroll-bg');
      navbar.classList.add('navbar-2-header-scroll-text');
    } else {
      navbar.classList.remove('navbar-2-header-scroll-bg');
      navbar.classList.remove('navbar-2-header-scroll-text');
    }
  };
  */

  /** mengganti logo ketika scroll */
  const [isScrolled, setIsScrolled] = useState(false);
  const listenScrollEvent = (e) => setIsScrolled(window.scrollY > DISTANCE_SCROLL_DOWN);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, []);

  /** update view navbar */
  const navbar = document.querySelector('.navbar-2');

  window.onscroll = () => {
    if (document.body.scrollTop > DISTANCE_SCROLL_DOWN || document.documentElement.scrollTop > DISTANCE_SCROLL_DOWN) {
      navbar.classList.add('navbar-2-header-scroll-bg');
      navbar.classList.add('navbar-2-header-scroll-text');
    } else {
      navbar.classList.remove('navbar-2-header-scroll-bg');
      navbar.classList.remove('navbar-2-header-scroll-text');
    }
  };

  return (
    <div className="navbar-2" id="id-navbar-2">
      <div className="navbar-2-container">
        <div className="navbar-2-horizontal-item">

          <div className="navbar-2-base-header">
            
            <div className="navbar-2-logo-header">
              <img src={isScrolled ? coloredLogo : whiteLogo} alt={isScrolled ? "SmartLogger white logo" : "SmartLogger colored logo"} className="left-logo" />
            </div>

            <div className="navbar-2-link-header">
              <div className="navbar-2-row-item">
                <Link to="/" className="text-item"><a>Home</a></Link>
              </div>

              <div className="navbar-2-row-item-dropdown">
                <Link className="text-item"><a>Project</a></Link>
       
                <div className="navbar-2-dropdown-content">
                  <div className="navbar-2-text-link">

                    <Link to="/house-project" className="navbar-2-text-link-text-item"><a>Rumah</a></Link>
                    <Link to="/interior-project" className="navbar-2-text-link-text-item"><a>Interior</a></Link>
                    <Link to="/masterplan-project" className="navbar-2-text-link-text-item"><a>Masterplan</a></Link>
                    <Link to="/commercial-project" className="navbar-2-text-link-text-item"><a>Komersial</a></Link>
                    <Link to="/mosque-project" className="navbar-2-text-link-text-item"><a>Masjid</a></Link>

                  </div>
                </div>
              </div>

              <div className="navbar-2-row-item">
                <Link to="/free-design" className="text-item"><a>Free Design</a></Link>
              </div>
              <div className="navbar-2-row-item">
                <Link to="/blog" className="text-item"><a>Blog</a></Link>
              </div>
              <div className="navbar-2-row-item">
                <Link to="/about-rekanata" className="text-item"><a>About</a></Link>
              </div>
              <div className="navbar-2-row-item">
                <Link to="/service" className="text-item"><a>Service</a></Link>
              </div>

              <div className="navbar-2-more-action-header">
                <Link to="/contact-us" className="text-item"><a>Contact Us</a></Link>
              </div>
            </div>
          </div>

          <div className="navbar-2-side-nav">
            <SideNav/>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Navbar2;