import React from 'react';
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa'; // You can use any icon library you prefer
import './FloatingBackToHome.css';

const FloatingBackToHome = () => {

  const navigate = useNavigate();

  function handleClick() {
    navigate("/"); {/** navigate to home */}
  }

  return (
    <button className="floating-back-to-home-button" onClick={handleClick}>
      <FaArrowLeft className="f-back-icon" />
    </button>
  );
};

export default FloatingBackToHome;