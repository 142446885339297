import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import './PortofolioItemComponent.css';
import portofolioImage from "../../../../assets/images/project/rumah/TRA_HOUSE/tarra-house.jpg";

function Portofolio09() {

    const navigate = useNavigate();
    const navigateHome = () => {
        navigate('/detail-project/portofolio-villa-house');
    };

    return (
        <div className='portofolio-rumah-01' id='id-portofolio-01'>
            <div className="portofolio-rumah-01-container">
                <div className='portofolio-rumah-01-container-img'>
                    <img src={portofolioImage} alt="imgFoto" className="portofolio-rumah-01-image" />
                </div>

                <div className="portofolio-rumah-01-overlay" onClick={navigateHome}>
                <h1>Villa House</h1>
                <p>Bogor, Jawa Barat</p>
                </div>
            </div>
        </div>
    );
}

export default Portofolio09;