import React from "react";

function DetailPortofolioSliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "portofolio-slides portofolio-active" : "portofolio-inactive"}
        >
          <img className="portofolio-slide-image" src={slide.urls} alt="" />
          <h2 className="portofolio-slide-title">{slide.title}</h2>
          <h3 className="portofolio-slide-text">{slide.description}</h3>
        </div>
      ))}
    </section>
  );
}

export default DetailPortofolioSliderContent;
