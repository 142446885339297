import './About.css'
import imageDesignAndBuild from '../assets/images/project/rumah/GW_HOUSE/grand-wisata-02.jpg'
import Testimonials from './Testimonials'
import SlidedownNavbar from '../interface/navbar/slide_down_navbar/SlideDownNavbar'
import Navbar2DarkBg from '../interface/navbar/Navbar2DarkBg';

function About() {

    /** animation slide up */
    function reveal1() {
        var reveals = document.querySelectorAll(".reveal-card-1");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 100;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-1");
            } else {
                reveals[i].classList.remove("active-card-1");
            }
        }
    }

    window.addEventListener("scroll", reveal1);

    function reveal2() {
        var reveals = document.querySelectorAll(".reveal-card-2");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 200;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active-card-2");
            } else {
                reveals[i].classList.remove("active-card-2");
            }
        }
    }

    window.addEventListener("scroll", reveal2);

    return (
        <div className='about-us-section' id='id-about-us-section'>

            <Navbar2DarkBg />
            
            <div className="about-us-content-header-hero-image">
                <div className="about-us-content-header-hero-text">
                    <a className='about-us-content-text-header'>
                        About Us
                    </a>
                </div>
            </div>

            <div className='about-us-container'>
                <div className='about-us-container-grid'>
                    <div className='about-us-left-row reveal-card-1'>
                        <a className='about-us-text-header'>
                            Company History
                        </a>
                        <a className='about-us-text-item'>
                            Studio NATA di dirikan pada tahun 2015 dengan menyediakan layanan
                            desain arsitektur dan interior yang mengutamakan inovasi, kualitas,
                            kenyamanan namun tetap memperhatikan budget. Seiring dengan kebutuhan
                            klien yang menginginkan desain dapat di aplikasikan dengan tepat sesuai rancangan,
                            maka kami melebarkan sayap dengan bersinergi dengan kontraktor untuk menghasilkan karya yang terbaik.
                            Studio NATA memastikan spesifikasi material sesuai dengan kesepakatan.
                            Kejujuran dengan klien menjadi salah satu prinsip kami.
                        </a>
                        <a className='about-us-text-item'>
                            “<b>Vision_</b> We bring lifestyle innovative concept and client satisfaction”
                        </a>
                        <a className='about-us-text-item'>
                            “<b>Mission_</b> Providing the best design and build”
                        </a>
                        <a className='about-us-text-item'>
                            Menggapai eskpektasi dengan kualitas, pembiayaan yang terkontrol dan menjemen waktu perencanaan dan pembangunan.
                        </a>
                    </div>

                    <div className='about-us-right-row reveal-card-2'>
                        <img src={imageDesignAndBuild} className='about-us-image' />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default About